import {useCallback, useEffect, useState} from "react";
import {FeatureCategory} from "../../models/feature";
import featureService from "../../services/feature.service";
import {Link} from "react-router-dom";

const FeatureCategoryListComponent = () => {
  const [categories, setCategories] = useState<FeatureCategory[]>([]);

  const getCategories = useCallback(() => {
    featureService.getFeatureCategories().then(setCategories);
  }, []);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const deleteCategory = (category: FeatureCategory) => {
    featureService.deleteCategory(category.id).then(() => {
      getCategories();
    })
  };

  return (
    <div className="table">

      <Link to={`/feature/category`}>Create</Link>

      <div className="row header-row">
        <div className="cell">Name</div>
        <div className="cell">Description</div>
        <div className="cell">Created</div>
        <div className="cell">Updated</div>
        <div className="cell">Actions</div>
      </div>

      {categories.map(f => (
        <div className="row" key={f.id}>
          <div className="cell">{f.name}</div>
          <div className="cell">{f.description}</div>
          <div className="cell">{f.created_at}</div>
          <div className="cell">{f.updated_at}</div>
          <div className="cell cell-actions">
            <Link to={`/feature/category/${f.id}`}>Edit</Link>
            <button className={'button'} onClick={() => deleteCategory(f)}>Delete</button>
          </div>
        </div>
      ))}

    </div>
  );
};

export default FeatureCategoryListComponent;
