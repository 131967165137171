import t from "../../../translation/services/translations";
import "./LoginComponent.scss";
import {useCallback, useEffect, useState} from "react";
import authService from "../../services/auth.service";
import googleIcon from "../../../../assets/landing/signin-google.png";
import fbIcon from "../../../../assets/landing/signin-facebook.png";
import {Link, useHistory, useLocation} from "react-router-dom";
import {EmailLoginData, LoginForm} from "../../models/LoginForm";
import {AuthProvider} from "../../models/AuthProvider";
import socialService from "../../services/social.service";
import {SocialData} from "../../models/SocialData";

function LoginComponent() {
  const [formState, setFormState] = useState({});
  const [errors, setErrors] = useState<{[name: string]: string[]}>({});

  const changeValue = (event: any) => {
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    setFormState({
      ...formState,
      [event.target.name]: value
    });
  };
  const history = useHistory();
  const location = useLocation();


  const referrer = window.location.origin + location.pathname;

  const login =(form: LoginForm) => {
    authService.login(form)
      .then(result => {
        history.replace("/journey");
      })
      .catch(exception => {
        console.log("Error", exception);
        if (exception.errors) {
          setErrors(exception.errors);
        }
      })
  };
  const loginCallback = useCallback(login, [history])

  useEffect(() => {
    if (location.search.includes("code")) {
      const params = new URLSearchParams(location.search)
      const data = {code: params.get('code'), referrer: referrer} as SocialData;
      const provider = params.get('state') ? AuthProvider.Google : AuthProvider.Facebook;
      loginCallback(new LoginForm(provider, data))
    }
  }, [location, referrer, loginCallback])

  const loginSocial = (provider: AuthProvider) => {
    window.location.href = socialService.getLoginUrl(provider, referrer);
  }

  const submitForm = (event: any) => {
    event.preventDefault();
    const data = formState as EmailLoginData;
    login(new LoginForm(AuthProvider.Email, data))
  }

  return (
    <div className="login">
      <h1>{t('Welcome Back!')}</h1>

      <div className="social-registration">
        <button onClick={() => {loginSocial(AuthProvider.Google)}} className="social-login button">
          <img src={googleIcon} alt="Sign up with Google" />
          {t("Sign up with Google")}
        </button>
        <button onClick={() => {loginSocial(AuthProvider.Facebook)}} className="social-login button">
          <img src={fbIcon} alt="Sign up with Facebook" />
          {t("Sign up with Facebook")}
        </button>
      </div>

      <p>{t("or")}</p>

      <div className="email-login">
        <form className={"email-login-form"} onSubmit={submitForm}>
          <div className={errors && errors.hasOwnProperty("email") ? "error input-group" : "input-group"}>
            <input type="email" name="email" placeholder={t("Email adress*")} required onChange={changeValue} />
            <small>{errors && errors.hasOwnProperty("email") ? errors.email : ""}</small>
          </div>
          <div className={errors && errors.hasOwnProperty("password") ? "error input-group" : "input-group"}>
            <input type="password" name="password" placeholder={t("Password*")} required onChange={changeValue} />
            <small>{errors && errors.hasOwnProperty("password") ? errors.password : ""}</small>
          </div>
          <div>
            <button className="main-btn" type="submit">{t('Log in')}</button>
          </div>
        </form>
      </div>

      <p>{t('Don’t have an account yet?')} <Link to="/auth/register">{t("Sign up")}</Link></p>
      <p>{t('Forgot Password?')} <Link to="/auth/reset">{t("Reset it")}</Link></p>
    </div>
  );
}

export default LoginComponent;
