import "./ChallengeRewardFormComponent.scss";
import {useHistory, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import challengeService from "../../services/challenge.service";
import {ChallengeRewardForm} from "../../models/ChallengeReward";

const ChallengeRewardFormComponent = () => {
  const {id} = useParams<{id?: string}>();
  const history = useHistory();
  const [form, setForm] = useState(new ChallengeRewardForm());

  useEffect(() => {
    if (id) {
      challengeService.getReward(id).then(goal => {
        setForm(new ChallengeRewardForm(goal));
      });
    }
  }, [id]);

  const changeValue = (callback: (form: ChallengeRewardForm) => void) => {
    const f = {...form};
    callback(f);
    setForm(f);
  };

  const saveReward = () => {
    (id ? challengeService.updateReward(id, form) : challengeService.createReward(form)).then(() => {
      history.push("/challenges/rewards");
    }).catch((error) => {
      console.log('Could not save condition', id, form, error);
    });
  };

  return (
    <div className="form">
      Challenge Reward


      <div className="form-field">
        <label>Name</label>
        <input type="string" onChange={e => changeValue(f => f.name = e.target.value)} name="is_active" value={form.name} />
      </div>

      <div className="form-field">
        <label>Description</label>
        <textarea onChange={e => changeValue(f => f.description = e.target.value)} name="name" value={form.description} />
      </div>

      <div className="form-field">
        <label>Image</label>
        <input type="string" onChange={e => changeValue(f => f.image = e.target.value)} name="is_active" value={form.image} />
      </div>


      <button onClick={saveReward}>Save</button>
    </div>
  );
};
export default ChallengeRewardFormComponent;
