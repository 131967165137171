import {User} from "../../auth/models/User";
import storeService from "./store.service";

const USER_STORAGE_KEY = "user_info";
const BEARER_STORAGE_KEY = "bearer";

class UserStoreService {
  private token: string | null;
  private user: User | null;

  constructor() {
    this.user = storeService.get(USER_STORAGE_KEY);
    this.token = storeService.get(BEARER_STORAGE_KEY);
  }

  getToken(): string | null {
    return this.token;
  }

  setToken(value: string | null) {
    value ? storeService.put(BEARER_STORAGE_KEY, value) : storeService.remove(USER_STORAGE_KEY);
    this.token = value;
  }

  getUser(): User | null {
    return this.user;
  }

  setUser(value: User | null) {
    value ? storeService.put(USER_STORAGE_KEY, value) : storeService.remove(USER_STORAGE_KEY);
    this.user = value;
  }
}

const userStoreService = new UserStoreService();
export default userStoreService;
