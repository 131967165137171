export class Department {
  id!: string;
  company_id!: string;
  name!: string;
  description!: string;
  is_active!: boolean;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;

  constructor(props: any) {
    Object.assign(this, props);
  }
}

export class DepartmentForm {
  name: string = '';
  description: string = '';
  is_active: boolean = true;
}
