import userStoreService from "../../common/services/user-store.service";
import FeatureCategoryListComponent from "../components/FeatureCategoryListComponent/FeatureCategoryListComponent";
import FeatureCategoryComponent from "../components/FeatureCategoryComponent/FeatureCategoryComponent";
import FeatureListComponent from "../components/FeatureListComponent/FeatureListComponent";
import FeatureComponent from "../components/FeatureComponent/FeatureComponent";

const routes = [
  {
    path: '/feature/categories',
    component: FeatureCategoryListComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
  {
    path: '/feature/category/:id',
    component: FeatureCategoryComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
  {
    path: '/feature/category',
    component: FeatureCategoryComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
  {
    path: '/features',
    component: FeatureListComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
  {
    path: '/feature/:id',
    component: FeatureComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
  {
    path: '/feature',
    component: FeatureComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
];

export default routes;
