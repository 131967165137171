import {
  ActivityForm,
  AssessmentForm,
  AssessmentQuestionForm,
  AssessmentResultLevel, AssessmentResultStage,
  AssessmentStepForm,
  QuestionOption
} from "../../models/ActivityForm";
import "./AssessmentFormComponent.scss";
import {AssessmentQuestionTypes} from "../../models/Assessment";
import {useEffect, useState} from "react";
import featureService from "../../../features/services/feature.service";
import {Feature} from "../../../features/models/feature";
import {FeatureFilter} from "../../../features/models/filters";

export interface AssessmentFormComponentProps {
  form: AssessmentForm;
  // onChange: (field: string, data: any) => void;
  onChange: (callback: (form: ActivityForm) => void) => void;
}

const AssessmentFormComponent = (props: AssessmentFormComponentProps) => {
  const [features, setFeatures] = useState<Feature[]>([]);
  useEffect(() => {
    const filter = new FeatureFilter();
    featureService.getFeatures(filter).then(setFeatures);
  }, []);

  const addStep = () => {
    const form = {...props.form};
    form.steps.push(new AssessmentStepForm());
    props.onChange(f => (f as AssessmentForm).steps = form.steps);
  };

  const addQuestion = (stepIdx: number) => {
    const steps = [...props.form.steps];
    steps[stepIdx].questions.push(new AssessmentQuestionForm());
    props.onChange(f => (f as AssessmentForm).steps = steps);
  };

  const addOption = (stepIdx: number, questionIdx: number) => {
    const steps = [...props.form.steps];
    steps[stepIdx].questions[questionIdx].options.push(new QuestionOption());
    props.onChange(f => (f as AssessmentForm).steps = steps);
  };

  const addLevel = () => {
    const result_levels = [...props.form.result_levels];
    result_levels.push(new AssessmentResultLevel());
    props.onChange(f => (f as AssessmentForm).result_levels = result_levels);
  };

  const changeQuestionField = (stepIdx: number, questionIdx: number, callback: (question: AssessmentQuestionForm) => void) => {
    const steps = [...props.form.steps];
    callback(steps[stepIdx].questions[questionIdx]);
    props.onChange(f => (f as AssessmentForm).steps = steps);
  };
  const changeOptionField = (stepIdx: number, questionIdx: number, optionIdx: number, callback: (option: QuestionOption) => void) => {
    const steps = [...props.form.steps];
    callback(steps[stepIdx].questions[questionIdx].options[optionIdx]);
    console.log('assd', steps);
    props.onChange(f => (f as AssessmentForm).steps = steps);
  };
  const changeLevelValue = (levelIdx: number, callback: (level: AssessmentResultLevel) => void) => {
    const result_levels: AssessmentResultLevel[] = [...props.form.result_levels];
    callback(result_levels[levelIdx]);
    props.onChange(f => (f as AssessmentForm).result_levels = result_levels);
  };

  const changeStepValue = (stepIdx: number, field: string, data: any) => {
    const steps = [...props.form.steps];
    steps[stepIdx][field as keyof AssessmentStepForm] = data;
    props.onChange(f => (f as AssessmentForm).steps = steps);
  };

  const removeStep = (stepIdx: number) => {
    const steps = [...props.form.steps];
    steps.splice(stepIdx, 1)
    props.onChange(f => (f as AssessmentForm).steps = steps);
  }

  const removeQuestion = (stepIdx: number, questionIdx: number) => {
    const steps = [...props.form.steps];
    steps[stepIdx].questions.splice(questionIdx, 1);
    props.onChange(f => (f as AssessmentForm).steps = steps);
  }

  const duplicateQuestion = (stepIdx: number, questionIdx: number) => {
    const steps = [...props.form.steps];
    const question = {...steps[stepIdx].questions[questionIdx]};
    steps[stepIdx].questions.push(question);
    props.onChange(f => (f as AssessmentForm).steps = steps);
  }

  const removeOption = (stepIdx: number, questionIdx: number, optionIdx: number) => {
    const steps = [...props.form.steps];
    steps[stepIdx].questions[questionIdx].options.splice(optionIdx, 1);
    props.onChange(f => (f as AssessmentForm).steps = steps);
  }


  console.log('assessment ', props.form);
  return (
    <div className="form-group">

      <div className="form-field">
        <label>Feature</label>
        <select onChange={e => {props.onChange(f => (f as AssessmentForm).feature_id = e.target.value)}} value={props.form.feature_id}>
          <option>Please Choose feature</option>
          {features.map(feature => (
            <option key={feature.id} value={feature.id}>{feature.name}</option>
          ))}
        </select>
      </div>

      <div className="form-field">
        <label>Is Psychological</label>
        <input type="checkbox" onChange={e => props.onChange(f => (f as AssessmentForm).is_psychological = e.target.checked)} name="is_psychological" checked={props.form.is_psychological} />
      </div>

      <h4>Steps</h4>
      {props.form.steps.map((step, index) => (
        <div key={index} className="form-group-item">

          <div className="form-group-row">
            <div className="form-field">
              <label>Name</label>
              <input type="text" onChange={e => {changeStepValue(index, 'name', e.target.value)}} name="name" value={step?.name} />
            </div>

            <div className="form-field">
              <label>Description (Tara Present step)</label>
              <textarea onChange={e => {changeStepValue(index, 'description', e.target.value)}} name="name" value={step?.description} />
            </div>

            <div className="form-field">
              <button onClick={() => removeStep(index)}>Remove</button>
            </div>
          </div>

          <div className="form-group">
            <h5>Questions</h5>
            {step.questions.map((question, questionIdx) => (
              <div key={questionIdx} className="form-group-item">

                <div className="form-group-row">
                  <div className="form-field">
                    <label>Question</label>
                    <input type="text" onChange={e => changeQuestionField(index, questionIdx, (q) => {q.question = e.target.value})} name="name" value={question.question} />
                  </div>

                  <div className="form-field">
                    <label>Type</label>
                    <select onChange={e => changeQuestionField(index, questionIdx, (q) => {q.type = e.target.value})} value={question.type}>
                      {Object.entries(AssessmentQuestionTypes).map(type => (
                        <option value={type[1]} key={type[1]}>{type[0]}</option>
                      ))}
                    </select>
                  </div>

                  <div className="form-field">
                    <label>Category (for results)</label>
                    <input type="text" onChange={e => changeQuestionField(index, questionIdx, (o) => {o.question_category = e.target.value})} name="name" value={question.question_category} />
                  </div>

                  <div className="form-field">
                    <label>Is Optional</label>
                    <input type="checkbox" onChange={e => changeQuestionField(index, questionIdx, (q) => {q.optional = !!e.target.value})} name="name" />
                  </div>

                  <div className="form-field">
                    <button onClick={() => removeQuestion(index, questionIdx)}>Remove</button>
                    <button onClick={() => duplicateQuestion(index, questionIdx)}>Duplicate</button>
                  </div>
                </div>

                {question.type !== 'text' && <div className="">
                  <h6>Options</h6>
                  {(question.options || []).map((option, optionIndex) => (
                    <div key={optionIndex} className="form-group-row">
                      <div className="form-field" style={{marginRight: 10}}>
                        <label>Text</label>
                        <input type="text" onChange={e => changeOptionField(index, questionIdx, optionIndex, (o) => {o.text = e.target.value})} name="name" value={option.text} />
                      </div>

                      <div className="form-field" style={{marginRight: 10}}>
                        <label>Points</label>
                        <input type="number" onChange={e => changeOptionField(index, questionIdx, optionIndex, (o) => {o.points = +e.target.value})} name="points" value={option.points} />
                      </div>

                      <div className="form-field" style={{marginRight: 10}}>
                        <label>Is right answer</label>
                        <input type="checkbox" onChange={e => changeOptionField(index, questionIdx, optionIndex, (o) => {o.is_valid_answer = e.target.checked})} name="is_valid_answer" checked={option.is_valid_answer} />
                      </div>

                      <div className="form-field" style={{marginRight: 10}}>
                        <label>Wrong Answer comment</label>
                        <input type="text" onChange={e => changeOptionField(index, questionIdx, optionIndex, (o) => {o.wrong_answer_explanation = e.target.value})} name="wrong_answer_explanation" value={option.wrong_answer_explanation} />
                      </div>

                      {question.type === 'voice' && (
                        <div className="form-field">
                          <label>Possible answers (through ,)</label>
                          <input type="text" onChange={e => changeOptionField(index, questionIdx, optionIndex, (o) => {o.possible_answers = e.target.value})} name="possible_answers" value={option.possible_answers} />
                        </div>
                      )}

                      <div className="form-field">
                        <button onClick={() => removeOption(index, questionIdx, optionIndex)}>Remove</button>
                      </div>
                    </div>
                  ))}

                  <button onClick={() => {addOption(index, questionIdx)}}>Add Options</button>
                </div>}

              </div>
            ))}
            <button onClick={() => {addQuestion(index)}}>Add Question</button>
          </div>
        </div>
      ))}

      <button onClick={addStep}>Add Step</button>

      <h4>Levels</h4>
      <div className="form-field">
        <label>Result description</label>
        <textarea onChange={e => props.onChange(f => (f as AssessmentForm).result_description = e.target.value) } name="name" value={props.form.result_description}/>
      </div>

        {(props.form.result_levels || []).map((level, levelIdx) => (
          <div key={levelIdx} className={"result-level"}>
            <div className="form-field">
              <label>Name</label>
              <input type="text" onChange={e => changeLevelValue(levelIdx, (resLevel) => {resLevel.name = e.target.value})} name="name" value={level.name} />
            </div>

            <div className="form-field">
              <label>Description</label>
              <textarea onChange={e => changeLevelValue(levelIdx, (resLevel) => {resLevel.description = e.target.value})} name="name" value={level?.description} />
            </div>

            <div className="form-field">
              <label>Stage</label>
              <select onChange={e => changeLevelValue(levelIdx, (resLevel) => {resLevel.stage = e.target.value as AssessmentResultStage})} value={level?.stage}>
                <option>Please Choose feature</option>
                {Object.entries(AssessmentResultStage).map(a => (
                  <option key={a[1]} value={a[1]}>{a[0]}</option>
                ))}
              </select>
            </div>

            <div className="form-field">
              <label>Category</label>
              <input type="text" onChange={e => changeLevelValue(levelIdx, (resLevel) => {resLevel.question_category = e.target.value})} name="name" value={level.question_category} />
            </div>

            <div className="form-field">
              <label>Min Value</label>
              <input type="text" onChange={e => changeLevelValue(levelIdx, (resLevel) => {resLevel.min = +e.target.value})} name="name" value={level.min} />
            </div>

            <div className="form-field">
              <label>Max Value</label>
              <input type="text" onChange={e => changeLevelValue(levelIdx, (resLevel) => {resLevel.max = +e.target.value})} value={level.max} />
            </div>
          </div>
        ))}
        <button onClick={addLevel}>Add Level</button>
    </div>
  );
};

export default AssessmentFormComponent;
