import userStoreService from "../../common/services/user-store.service";
import RateComponent from "../components/RateComponent/RateComponent";

const routes = [
  {
    path: '/rates',
    component: RateComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
];

export default routes;
