import {Activity, ActivityType} from "./Activity";

export class ActivityForm {
  type!: ActivityType;
  name!: string;
  code!: string;
  image!: string;
  image_path!: string;
  description!: string;
  feature_category_id!: string;
  is_public!: boolean; // coming soon
  is_premium!: boolean;
  is_active!: boolean;
  benefits: string[] = [];
  time_estimation?: number;

  static fromActivity(activity: Activity) {
    let form;
    switch (activity.type) {
      case ActivityType.Assessment:
        form = AssessmentForm.fromActivityData(activity);
        break;
      case ActivityType.SoundTherapy:
        form = SoundSessionForm.fromActivityData(activity);
        break;
      case ActivityType.Exercise:
        form = ExerciseForm.fromActivityData(activity);
        break;
    }
    form.benefits = (activity.benefits || []).map(b => b.id + '');
    console.log('form', form);
    return form;
  }
}

export class SoundSessionForm extends ActivityForm{
  isHeadphoneNeeded: boolean = true;
  file!: string;
  video?: string;
  has_voice: boolean = false;

  static fromForm(data: ActivityForm) {
    const form = new SoundSessionForm();
    Object.assign(form, data);
    return form;
  }

  static fromActivityData(data: Activity) {
    const form = new SoundSessionForm();
    Object.assign(form, data);
    return form;
  }
}

export class QuestionOption {
  text!: string;
  points!: number;
  is_valid_answer?: boolean;
  wrong_answer_explanation?: string;
  possible_answers!: string;
}

export class AssessmentQuestionForm {
  question!: string;
  type!: string; //'voice' | 'text' | 'radio'
  options: QuestionOption[] = [];
  optional!: boolean;
  question_category?: string;
}

export enum AssessmentResultStage {
  Low = "low",
  Medium = "medium",
  critical = "critical",
}

export class AssessmentResultLevel {
  min!: number;
  max!: number;
  description!: string;
  name!: string;
  question_category?: string;
  stage?: AssessmentResultStage;
}

export class AssessmentStepForm {
  name?: string;
  description?: string;
  questions: AssessmentQuestionForm[] = [];
}

export class AssessmentForm extends ActivityForm {
  steps: AssessmentStepForm[] = [];
  result_levels: AssessmentResultLevel[] = [];
  is_psychological: boolean = false;
  result_description?: string;
  feature_id?: string;

  static fromForm(data: ActivityForm) {
    const form = new AssessmentForm();
    Object.assign(form, data);
    return form;
  }

  static fromActivityData(data: Activity) {
    const form = new AssessmentForm();
    Object.assign(form, data);
    return form;
  }
}

export class ExerciseCommandForm {
  type!: string;
  delayMs?: number;
  instruction?: string;
  repeat?: number;
  image?: string;
  question?: string;
  // questionFilter: any = {};
}

export enum ExerciseTypes {
  Breathing = 'breathing',
  Education = 'education',
  Guided = 'guided',
  Reflection = 'reflection',
}

export class ExerciseForm extends ActivityForm {
  exercise_type?: string;
  commands: ExerciseCommandForm[] = [];

  static fromForm(data: ActivityForm) {
    const form = new ExerciseForm();
    Object.assign(form, data);
    return form;
  }

  static fromActivityData(data: Activity) {
    const form = new ExerciseForm();
    Object.assign(form, data);
    return form;
  }
}
