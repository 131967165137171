import {Link, useLocation} from "react-router-dom";

const ChallengeMenuComponent = () => {
  const location = useLocation();

  return (
    <div className="menu">
      {location.pathname !== '/challenges' && <Link to={`/challenges`}>Challenges</Link>}
      {location.pathname !== '/challenges/goals' && <Link to={`/challenges/goals`}>Goals</Link>}
      {location.pathname !== '/challenges/rewards' && <Link to={`/challenges/rewards`}>Rewards</Link>}
      {location.pathname !== '/challenges/conditions' && <Link to={`/challenges/conditions`}> Reward Conditions</Link>}
    </div>
  );
};
export default ChallengeMenuComponent;
