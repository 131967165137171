import userStoreService from "../../common/services/user-store.service";
import ChallengeList from "../components/ChallengeList/ChallengeList";
import ChallengeGoalList from "../components/ChallengeGoalList/ChallengeGoalList";
import ChallengeRewardList from "../components/ChallengeRewardList/ChallengeRewardList";
import ChallengeConditionList from "../components/ChallengeConditionList/ChallengeConditionList";
import ChallengeConditionFormComponent from "../components/ChallengeConditionFormComponent/ChallengeConditionFormComponent";
import ChallengeFormComponent from "../components/ChallengeFormComponent/ChallengeFormComponent";
import ChallengeGoalFormComponent from "../components/ChallengeGoalFormComponent/ChallengeGoalFormComponent";
import ChallengeRewardFormComponent from "../components/ChallengeRewardFormComponent/ChallengeRewardFormComponent";

const routes = [
  {
    path: '/challenges/goals',
    component: ChallengeGoalList,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
  {
    path: '/challenges/goal/:id',
    component: ChallengeGoalFormComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
  {
    path: '/challenges/goal',
    component: ChallengeGoalFormComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
  {
    path: '/challenges/rewards',
    component: ChallengeRewardList,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
  {
    path: '/challenges/reward/:id',
    component: ChallengeRewardFormComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
  {
    path: '/challenges/reward',
    component: ChallengeRewardFormComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
  {
    path: '/challenges/conditions',
    component: ChallengeConditionList,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
  {
    path: '/challenges/condition/:id',
    component: ChallengeConditionFormComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
  {
    path: '/challenges/condition',
    component: ChallengeConditionFormComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
  {
    path: '/challenge/:id',
    component: ChallengeFormComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
  {
    path: '/challenge',
    component: ChallengeFormComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
  {
    path: '/challenges',
    component: ChallengeList,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
];

export default routes;
