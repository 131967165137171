import {AuthProvider} from "../models/AuthProvider";
import {FacebookProvider} from "./providers/facebook.provider";
import {GoogleProvider} from "./providers/google.provider";
import {SocialProviderService} from "./providers/social.provider";

class SocialService {

  PROVIDER_MAPPINGS: {[key: string]: SocialProviderService} = {
    [AuthProvider.Facebook]: new FacebookProvider(),
    [AuthProvider.Google]: new GoogleProvider(),
  }

  getLoginUrl(provider: AuthProvider, redirectUrl: string): string {
    if (!this.PROVIDER_MAPPINGS.hasOwnProperty(provider)) {
      throw new Error(`No such provider ${provider}`);
    }

    const providerService = this.PROVIDER_MAPPINGS[provider]
    return providerService.getLoginUrl(redirectUrl);
  }

}

const socialService = new SocialService();
export default socialService;
