import {ActivityForm, SoundSessionForm} from "../../models/ActivityForm";

export interface SoundSessionFormProps {
  form: SoundSessionForm;
  // onChange: (field: string, data: any) => void;
  onChange: (callback: (form: ActivityForm) => void) => void;
}

const SoundSessionFormComponent = (props: SoundSessionFormProps) => {

  return (
    <div>

      <div className="form-field">
        <label>Sound</label>
        <input type="text" onChange={e => props.onChange(f => (f as SoundSessionForm).file = e.target.value)} name="file" value={props.form?.file} />
      </div>

      <div className="form-field">
        <label>Video</label>
        <input type="text" onChange={e => props.onChange(f => (f as SoundSessionForm).video = e.target.value)} name="video" value={props.form?.video} />
      </div>

      <div className="form-field">
        <label>Has Voice</label>
        <input type="checkbox" onChange={e => props.onChange(f => (f as SoundSessionForm).has_voice = e.target.checked)} name="has_voice" checked={props.form?.has_voice} />
      </div>

    </div>
  );
};

export default SoundSessionFormComponent;
