import {FeatureCategoryFilter, FeatureFilter} from "../models/filters";
import Pagination from "../../common/models/Pagination";
import {Feature, FeatureCategory, FeatureCategoryForm, FeatureForm} from "../models/feature";
import fetchService from "../../common/services/fetch.service";

class FeatureService {

  getFeatures(filter?: FeatureFilter, pagination?: Pagination)  {
    return fetchService.fetchApi<Feature[]>(`/api/admin/features`, {
      method: 'GET',
    });
  }

  getFeatureCategories(filter?: FeatureCategoryFilter, pagination?: Pagination) {
    return fetchService.fetchApi<FeatureCategory[]>(`/api/admin/feature/categories`, {
      method: 'GET',
    });
  }

  getFeature(id: string) {
    return fetchService.fetchApi<Feature>(`/api/admin/feature/${id}`, {
      method: 'GET',
    });
  }

  getFeatureCategory(id: string) {
    return fetchService.fetchApi<Feature>(`/api/admin/feature/category/${id}`, {
      method: 'GET',
    });
  }

  createFeature(form: FeatureForm) {
    return fetchService.fetchApi<Feature>(`/api/admin/feature`, {
      method: 'POST',
      body: JSON.stringify(form),
    });
  }

  updateFeature(id: string, form: FeatureForm) {
    return fetchService.fetchApi<Feature>(`/api/admin/feature/${id}`, {
      method: 'PATCH',
      body: JSON.stringify(form),
    });
  }

  createCategory(form: FeatureCategoryForm) {
    console.log('create category');
    return fetchService.fetchApi<FeatureCategory>(`/api/admin/feature/category`, {
      method: 'POST',
      body: JSON.stringify(form),
    });
  }

  updateCategory(id: string, form: FeatureCategoryForm): Promise<FeatureCategory> {
    console.log('update category');
    return fetchService.fetchApi<FeatureCategory>(`/api/admin/feature/category/${id}`, {
      method: 'PATCH',
      body: JSON.stringify(form),
    });
  }

  deleteCategory(id: string): Promise<any> {
    console.log('update category');
    return fetchService.fetchApi<FeatureCategory>(`/api/admin/feature/category/${id}`, {
      method: 'DELETE',
      body: null,
    });
  }

}

const featureService = new FeatureService();
export default featureService;
