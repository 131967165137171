
import {UserActivitiesFilters, UserActivitiesResponse} from "../models/UserActivities";
import Pagination from "../../common/models/Pagination";
import fetchService from "../../common/services/fetch.service";


class   UserActivitiesService {

  getUserActivities(filter: UserActivitiesFilters, pagination: Pagination | null = null) {
    const search = fetchService.objectToPath(filter);
    const paging = fetchService.objectToPath(pagination);
    return fetchService.fetchApi<UserActivitiesResponse>(`/api/admin/user/activities?${search}&${paging}`, {
      method: "GET"
    }).then(result => {
      return new UserActivitiesResponse(result.count, result.user_activities);
    });
  }
}

const userActivitiesServices = new UserActivitiesService();
export default userActivitiesServices;
