import {Activity} from "./Activity";

export enum ExerciseCommandType {
  Wait = 'wait',
  Instruct = 'instruct',
  Approve = 'approve',
  ApproveButton = "approve_button",
  ShowText = "show_text",
  RepeatStart = "repeat_start",
  RepeatEnd = "repeat_end",
  BreathIn = "breath_in",
  HolAfterBreathIn = "hold_after_breath_in",
  BreathOut = "breath_out",
  HoldAfterBreathOut = "hold_after_breath_out",
  AskQuestion = "ask_question",
}

export class ExerciseCommand {
  type!: ExerciseCommandType;
  delayMs?: number;
  instruction?: string;
  repeat?: number;

  constructor(props: any) {
    Object.assign(this, props);
  }
}

export class Exercise extends Activity{
  exercise_type?: string;
  commands: ExerciseCommand[] = [];

  constructor(props: any) {
    super(props);
    Object.assign(this, props);
    this.commands = (props.commands||[]).map((c: any) => new ExerciseCommand(c));
  }
}
