import {Challenge, ChallengeFilter, ChallengeForm} from "../models/Challenge";
import fetchService from "../../common/services/fetch.service";
import {ChallengeGoal, ChallengeGoalFilter, ChallengeGoalForm} from "../models/ChallengeGoal";
import {ChallengeReward, ChallengeRewardFilter, ChallengeRewardForm} from "../models/ChallengeReward";
import {
  ChallengeRewardCondition,
  ChallengeRewardConditionFilter,
  ChallengeRewardConditionForm
} from "../models/ChallengeRewardCondition";

class ChallengeService {
  getChallenges(filter: ChallengeFilter): Promise<Challenge[]> {
    return fetchService.fetchApi<Challenge[]>("/api/admin/challenges", {
      method: "GET",
    }).then(data => {
      return data.map(d => new Challenge(d));
    });
  }

  getChallenge(id: string): Promise<Challenge> {
    return fetchService.fetchApi<Challenge>(`/api/admin/challenge/${id}`, {
      method: "GET",
    }).then(data => {
      return new Challenge(data);
    });
  }

  createChallenge(form: ChallengeForm): Promise<Challenge> {
    return fetchService.fetchApi<Challenge>(`/api/admin/challenge`, {
      method: "POST",
      body: JSON.stringify(form)
    });
  }

  updateChallenge(id: string, form: ChallengeForm): Promise<Challenge> {
    return fetchService.fetchApi<Challenge>(`/api/admin/challenge/${id}`, {
      method: "PATCH",
      body: JSON.stringify(form)
    });
  }

  getGoals(filter: ChallengeGoalFilter): Promise<ChallengeGoal[]> {
    return fetchService.fetchApi<ChallengeGoal[]>("/api/admin/challenges/goals", {
      method: "GET",
    }).then(data => {
      return data.map(d => new ChallengeGoal(d));
    });
  }

  getGoal(id: string): Promise<ChallengeGoal> {
    return fetchService.fetchApi<ChallengeGoal>(`/api/admin/challenges/goal/${id}`, {
      method: "GET",
    });
  }

  createGoal(form: ChallengeGoalForm): Promise<ChallengeGoal> {
    return fetchService.fetchApi<ChallengeGoal>(`/api/admin/challenges/goal`, {
      method: "POST",
      body: JSON.stringify(form)
    });
  }

  updateGoal(id: string, form: ChallengeGoalForm): Promise<ChallengeGoal> {
    return fetchService.fetchApi<ChallengeGoal>(`/api/admin/challenges/goal/${id}`, {
      method: "PATCH",
      body: JSON.stringify(form)
    });
  }

  getRewards(filter: ChallengeRewardFilter): Promise<ChallengeReward[]> {
    return fetchService.fetchApi<ChallengeReward[]>("/api/admin/challenges/rewards", {
      method: "GET",
    }).then(data => {
      return data.map(d => new ChallengeReward(d));
    });
  }

  getReward(id: string): Promise<ChallengeReward> {
    return fetchService.fetchApi<ChallengeReward>(`/api/admin/challenges/reward/${id}`, {
      method: "GET",
    });
  }

  createReward(form: ChallengeRewardForm): Promise<ChallengeReward> {
    return fetchService.fetchApi<ChallengeReward>(`/api/admin/challenges/reward`, {
      method: "POST",
      body: JSON.stringify(form)
    });
  }

  updateReward(id: string, form: ChallengeRewardForm): Promise<ChallengeReward> {
    return fetchService.fetchApi<ChallengeReward>(`/api/admin/challenges/reward/${id}`, {
      method: "PATCH",
      body: JSON.stringify(form)
    });
  }

  getRewardConditions(filter: ChallengeRewardConditionFilter): Promise<ChallengeRewardCondition[]> {
    return fetchService.fetchApi<ChallengeRewardCondition[]>("/api/admin/challenges/conditions", {
      method: "GET",
    }).then(data => {
      return data.map(d => new ChallengeRewardCondition(d));
    });
  }

  getRewardCondition(id: string): Promise<ChallengeRewardCondition> {
    return fetchService.fetchApi<ChallengeRewardCondition>(`/api/admin/challenges/condition/${id}`, {
      method: "GET",
    });
  }

  createRewardCondition(form: ChallengeRewardConditionForm): Promise<ChallengeRewardCondition> {
    return fetchService.fetchApi<ChallengeRewardCondition>(`/api/admin/challenges/condition`, {
      method: "POST",
      body: JSON.stringify(form)
    });
  }

  updateRewardCondition(id: string, form: ChallengeRewardConditionForm): Promise<ChallengeRewardCondition> {
    return fetchService.fetchApi<ChallengeRewardCondition>(`/api/admin/challenges/condition/${id}`, {
      method: "PATCH",
      body: JSON.stringify(form)
    });
  }
}

const challengeService = new ChallengeService();
export default challengeService;
