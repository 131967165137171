import "./ChallengeList.scss";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Challenge, ChallengeFilter} from "../../models/Challenge";
import challengeService from "../../services/challenge.service";
import ChallengeMenuComponent from "../ChallengeMenuComponent";

const ChallengeList = () => {
  const [challenges, setChallenges] = useState<Challenge[]>([]);

  useEffect(() => {
    challengeService.getChallenges(new ChallengeFilter()).then(setChallenges);
  }, []);

  return (
    <div>
      <ChallengeMenuComponent />

      <Link to={`/challenge`}>Create</Link>

      <div className="table full-width">
        <div className="row table-header">
          <div className="cell">Name</div>
          <div className="cell">Goal</div>
          <div className="cell">Reward</div>
          <div className="cell">Starts At</div>
          <div className="cell">Ends At</div>
          <div className="cell">Created At</div>
          <div className="cell">Actions</div>
        </div>

        {challenges.map(c => (
          <div className="row" key={c.id}>
            <div className="cell">{c.name}</div>
            <div className="cell">{c.goal?.type || ''}</div>
            <div className="cell">{c.reward?.name || ''}</div>
            <div className="cell">{c.started_at}</div>
            <div className="cell">{c.ended_at}</div>
            <div className="cell">{c.created_at}</div>
            <div className="cell cell-actions">
              <Link to={`/challenge/${c.id}`}>Edit</Link>
            </div>
          </div>
        ))}

        <div className="row table-footer">
        </div>

      </div>
    </div>
  );
};
export default ChallengeList;
