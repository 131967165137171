import {UserSessionActivityFilter} from "../models/UserJourneyFilters";
import Pagination from "../../common/models/Pagination";
import fetchService from "../../common/services/fetch.service";
import {UserSessionActivity} from "../models/UserJourney";

class UserJourneyService {

  filterUserActivities(filter: UserSessionActivityFilter, pagination?: Pagination) {
    const search = fetchService.objectToPath(filter);
    const paging = fetchService.objectToPath(pagination);
    return fetchService.fetchApi<UserSessionActivity[]>(`/api/admin/journeys/user/activities?${search}&${paging}`, {
      method: "GET"
    }).then(result => {
      return result.map(data => new UserSessionActivity(data));
    });
  }

}

const userJourneyService = new UserJourneyService();
export default userJourneyService;
