import React from "react";
import "./LayoutComponent.scss";
import RouteListComponent from "../RouteListComponent/RouteListComponent";
import FooterComponent from "../FooterComponent/FooterComponent";
import HeaderComponent from "../HeaderComponent/HeaderComponent";
import ErrorComponent from "../ErrorComponent/ErrorComponent";

function LayoutComponent(props: any) {

  return (
    <div>
      <HeaderComponent/>
      <main className="app">
        <RouteListComponent routes={props.routes}/>
      </main>
      <FooterComponent/>
      <ErrorComponent />
    </div>
  );
}

export default LayoutComponent;
