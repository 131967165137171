import React, {useEffect, useRef} from 'react';
import './App.scss';
import WeVoiceRouter from './components/WeVoiceRouter/WeVoiceRouter';
import LayoutComponent from "./modules/common/components/LayoutComponent/LayoutComponent";
import {useLocation} from "react-router-dom";
import routes from "./routes";

function ScrollToTop() {
  let isInitialMount = useRef(true);
  const location = useLocation();
  useEffect(() => {
    if (!location.hash && !isInitialMount.current) {
      window.scrollTo(0, 0);
    }
    isInitialMount.current = false;
  }, [location]);
  return null;
}

function App() {
  return (
    <WeVoiceRouter>
      <ScrollToTop />
      <LayoutComponent routes={routes} />
    </WeVoiceRouter>
  );
}

export default App;
