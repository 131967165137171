import fetchService from "../../common/services/fetch.service";
import {Company, CompanyFilter, CompanyForm} from "../models/Company";
import {Department, DepartmentForm} from "../models/Department";
import formService from "../../common/services/form.service";

class CompanyService {
  createCompany(form: CompanyForm): Promise<Company> {
    return fetchService.fetchApi<Company>(`/api/admin/company`, {
      method: "POST",
      body: formService.convertObjectIntoFormData(form),
      headers:{}
    });
  }

  updateCompany(id: string, form: CompanyForm): Promise<Company> {
    return fetchService.fetchApi<Company>(`/api/admin/company/${id}`, {
      method: "PATCH",
      body: formService.convertObjectIntoFormData(form),
      headers:{}
    }).then(data => {
      return new Company(data);
    });
  }

  getCompany(id: string): Promise<Company | null> {
    return fetchService.fetchApi<Company>(`/api/admin/company/${id}`, {
      method: "GET",
    }).then(data => {
      return new Company(data);
    });
  }

  getCompanies(filter: CompanyFilter): Promise<Company[]> {
    return fetchService.fetchApi<Company[]>(`/api/admin/companies`, {
      method: "GET",
    }).then(data => {
      return data.map(d => new Company(d));
    });
  }

  createDepartment(company_id: string, form: DepartmentForm): Promise<Department> {
    return fetchService.fetchApi<Department>(`/api/admin/company/${company_id}/department`, {
      method: "POST",
      body: JSON.stringify(form)
    });
  }

  updateDepartment(company_id: string, department_id: string, form: DepartmentForm): Promise<Department> {
    return fetchService.fetchApi<Department>(`/api/admin/company/${company_id}/department/${department_id}`, {
      method: "PATCH",
      body: JSON.stringify(form)
    });
  }

}

const companyService = new CompanyService();
export default companyService;
