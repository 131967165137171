import {NotificationForm} from "../models/NotificationForm";
import fetchService from "../../common/services/fetch.service";
import {Journey} from "../../journeys/models/Journey";

class NotificationService {
  sendNotification(form: NotificationForm) {
    return fetchService.fetchApi<Journey>(`/api/admin/notification`, {
      method: 'POST',
      body: JSON.stringify(form)
    });
  }
}

const notificationService = new NotificationService();
export default notificationService;
