import referralCodeService from "../../services/referral-code.service";
import {useEffect, useState} from "react";
import {ReferralCode} from "../../models/ReferralCode";
import {Link} from "react-router-dom";

const ReferralListComponent = () => {
  const [referrals, setReferrals] = useState<ReferralCode[]>([]);

  useEffect(() => {
    referralCodeService.getReferrals().then(setReferrals);
  }, []);

  return (
    <div>
      <Link to={`/referral`}>Create</Link>

      <div className="table full-width">
        <div className="row table-header">
          <div className="cell">Code</div>
          <div className="cell">Deleted At</div>
          <div className="cell">Actions</div>
        </div>

        {referrals.map(a => (
          <div className="row" key={a.id}>
            <div className="cell">{a.code}</div>
            <div className="cell">{a.deleted_at}</div>
            <div className="cell cell-actions">
              <Link to={`/referral/${a.id}`}>Edit</Link>
            </div>
          </div>
        ))}

        <div className="row table-footer">
        </div>

      </div>
    </div>
  );
};

export default ReferralListComponent;

