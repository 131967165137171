export enum ChallengeGoalType {
  Meditation = "Meditation",
  SleepStart = "SleepStart",
  SleepMinutes = "SleepMinutes",
  Steps = "Steps",
}

export class ChallengeGoal {
  id!: string;
  type!: string;
  value!: number;
  is_sequence!: boolean;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;

  constructor(props: any = {}) {
    Object.assign(this, props);
  }

  getName() {
    return `${this.type} ${this.value}`;
  }
}

export class ChallengeGoalFilter {}

export class ChallengeGoalForm {
  type!: string;
  value!: number;
  is_sequence!: boolean;

  constructor(props: any = {}) {
    Object.assign(this, props);
  }
}
