import {useHistory, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {ReferralCodeForm} from "../../models/ReferralCodeForm";
import referralCodeService from "../../services/referral-code.service";
import "./ReferralFormComponent.scss";
import {Journey, JourneyFilter} from "../../../journeys/models/Journey";
import journeyService from "../../../journeys/services/journey.service";
import {Company, CompanyFilter} from "../../../company/models/Company";
import companyService from "../../../company/services/company.service";

const ReferralFormComponent = () => {
  const {id} = useParams<{id?: string}>();
  const [form, setForm] = useState<ReferralCodeForm>(new ReferralCodeForm(''));
  const [journeys, setJourneys] = useState<Journey[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>()
  const [companies, setCompanies] = useState<Company[]>([]);
  const history = useHistory();

  useEffect(() => {
    const filter = new JourneyFilter();
    journeyService.getJourneys(filter).then(setJourneys);
  }, []);

  useEffect(() => {
    const filter = new CompanyFilter();
    companyService.getCompanies(filter).then(companies => {
      setCompanies(companies);
    });
  }, []);

  useEffect(() => {
    console.log('load', id);
    if (id) {
      referralCodeService.getReferral(id).then(data => {
        if (data) {
          setForm(new ReferralCodeForm(data.code, data.journey_id, data.department_id));
        } else {
          history.goBack();
        }
      });
    }
  }, [history, id]);

  useEffect(() => {
    const company = companies && form.department_id ? companies.find(c => c.departments.some(d => d.id === form.department_id)) : undefined;
    setSelectedCompanyId(company?.id);
  }, [form.department_id, companies, setSelectedCompanyId]);

  const changeValue = (field: string, value: any) => {
    const f = {...form};
    f[field as keyof ReferralCodeForm] = value;
    setForm(f);
  };

  const saveReferral = () => {
    (id ? referralCodeService.updateReferral(id, form) : referralCodeService.createReferral(form)).then(c => {
      history.push("/referrals");
    });
  };

  console.log('sel company', selectedCompanyId);
  const selectedCompany = selectedCompanyId ? companies.find(c => c.id === selectedCompanyId) : null;

  return (
    <div className="form">
      Rate form
      <div className="form-field">
        <label>Code</label>
        <input type="text" onChange={e => {changeValue('code', e.target.value)}} name="code" value={form?.code} />
      </div>

      <div className="form-field">
        <label>Journey</label>
        <select className="form-input select" onChange={e => {changeValue('journey_id', e.target.value)}} value={form.journey_id}>
          <option>Choose journey</option>
          {journeys.map(a => (
            <option key={a.id} value={a.id}>{a.name}</option>
          ))}
        </select>
      </div>

      <div className="form-field">
        <label>Company</label>
        <select className="form-input select" onChange={e => {setSelectedCompanyId(e.target.value)}} value={selectedCompanyId || ''}>
          <option>Choose company</option>
          {companies.map(a => (
            <option key={a.id} value={a.id}>{a.name}</option>
          ))}
        </select>
      </div>

      {selectedCompany && <div className="form-field">
        <label>Department</label>
        <select className="form-input select" onChange={e => {changeValue('department_id', e.target.value)}} value={form.department_id}>
          <option>Choose department</option>
          {(selectedCompany.departments || []).map(a => (
            <option key={a.id} value={a.id}>{a.name}</option>
          ))}
        </select>
      </div>}

      <button onClick={saveReferral}>Save</button>
    </div>
  );
};

export default ReferralFormComponent;
