import {useState} from "react";
import {JourneyForm} from "../../models/Journey";
import journeyService from "../../services/journey.service";
import {useHistory} from "react-router-dom";

const JourneyFormComponent = () => {
  const [journeyForm, setJourneyForm] = useState<JourneyForm>(new JourneyForm());
  const [errors, setErrors] = useState<any>({});
  const history = useHistory();

  const changeValue = (callback: (form: JourneyForm) => void) => {
    const form = {...journeyForm};
    callback(form);
    setJourneyForm(form);
  };

  const saveJourney = () => {
    journeyService.createJourney(journeyForm).then(() => {
      history.push("/journeys");
    }).catch(setErrors);
  };

  return (
    <div>
      <div className="form-field">
        <label>Name</label>
        <input type="text" onChange={e => {changeValue(f => f.name = e.target.value)}} name="name" value={journeyForm?.name} />
        <span>{errors.hasOwnProperty('name') ? errors.name : ''}</span>
      </div>

      <div className="form-field">
        <label>Description</label>
        <input type="text" onChange={e => {changeValue(f => f.description = e.target.value)}} name="name" value={journeyForm?.description} />
      </div>

      <div className="form-field">
        <label>Image</label>
        <input type="file" onChange={e => {changeValue(f => f.image = e.target.files ? e.target.files[0] : undefined)}} name="name" />
      </div>

      <div className="form-field">
        <label>Mail list</label>
        <input type="text" onChange={e => {changeValue(f => f.mail_list_id = e.target.value)}} name="mail_list_id" value={journeyForm?.mail_list_id} />
      </div>

      <div className="form-field">
        <label>Is Active</label>
        <input type="checkbox" onChange={e => changeValue(f => f.is_active = e.target.checked)} name="is_active" checked={journeyForm?.is_active}  />
      </div>

      <div className="form-field">
        <label>Is Public</label>
        <input type="checkbox" onChange={e => changeValue(f => f.is_public = e.target.checked)} name="is_public" checked={journeyForm?.is_public}  />
      </div>

      <div className="form-field">
        <label>With Certificate</label>
        <input type="checkbox" onChange={e => changeValue(f => f.with_certificate = e.target.checked)} name="with_certificate" checked={journeyForm?.with_certificate}  />
      </div>

      <button onClick={saveJourney}>Save</button>
    </div>
  );
};

export default JourneyFormComponent;
