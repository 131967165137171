import fetchService from "../../common/services/fetch.service";
import {ReflectionQuestion, ReflectionQuestionForm} from "../models/Reflection";

class ReflectionService {
  getReflections() {
    return fetchService.fetchApi<ReflectionQuestion[]>(`/api/admin/reflections`, {
      method: "GET"
    });
  }

  getReflection(id: string) {
    return fetchService.fetchApi<ReflectionQuestion[]>(`/api/admin/reflection/${id}`, {
      method: "GET"
    });
  }

  updateReflection(id: string, form: ReflectionQuestionForm) {
    return fetchService.fetchApi(`/api/admin/reflection/${id}`, {
      method: "PATCH",
      body: JSON.stringify(form)
    });
  }

  createReflection(form: ReflectionQuestionForm) {
    return fetchService.fetchApi(`/api/admin/reflection`, {
      method: "POST",
      body: JSON.stringify(form)
    });
  }
}

const reflectionService = new ReflectionService();
export default reflectionService;
