import "./ChallengeFormComponent.scss";
import {useHistory, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import challengeService from "../../services/challenge.service";
import {ChallengeForm, ChallengeType} from "../../models/Challenge";
import DatePicker from "react-datepicker";
import {ChallengeGoal, ChallengeGoalFilter} from "../../models/ChallengeGoal";
import {ChallengeReward, ChallengeRewardFilter} from "../../models/ChallengeReward";
import {ChallengeRewardCondition, ChallengeRewardConditionFilter} from "../../models/ChallengeRewardCondition";
import {Company, CompanyFilter} from "../../../company/models/Company";
import companyService from "../../../company/services/company.service";

const ChallengeFormComponent = () => {
  const {id} = useParams<{id?: string}>();
  const history = useHistory();
  const [form, setForm] = useState<ChallengeForm>(new ChallengeForm());

  const [companies, setCompanies] = useState<Company[]>([]);
  const [goals, setGoals] = useState<ChallengeGoal[]>([]);
  const [rewards, setRewards] = useState<ChallengeReward[]>([]);
  const [conditions, setConditions] = useState<ChallengeRewardCondition[]>([]);

  useEffect(() => {
    companyService.getCompanies(new CompanyFilter()).then(setCompanies)
  }, []);

  useEffect(() => {
    challengeService.getGoals(new ChallengeGoalFilter()).then(setGoals)
  }, []);

  useEffect(() => {
    challengeService.getRewards(new ChallengeRewardFilter()).then(setRewards)
  }, []);

  useEffect(() => {
    challengeService.getRewardConditions(new ChallengeRewardConditionFilter()).then(setConditions)
  }, []);

  useEffect(() => {
    if (id) {
      challengeService.getChallenge(id).then(goal => {
        console.log('Load challkenge');
        setForm(new ChallengeForm(goal));
      });
    }
  }, [id]);

  const changeValue = (callback: (form: ChallengeForm) => void) => {
    const f = new ChallengeForm(form);
    callback(f);
    setForm(f);
  };

  const saveReward = () => {
    (id ? challengeService.updateChallenge(id, form) : challengeService.createChallenge(form)).then(() => {
      history.push("/challenges");
    }).catch((error) => {
      console.log('Could not save condition', id, form, error);
    });
  };

  console.log('Challenge', form);
  return (
    <div className="form">
      Challenge

      <div className="form-field">
        <label></label>
        <select className="form-input select" onChange={(e) => changeValue(f => f.type = e.target.value)} value={form.type}>
          <option>Type of the challenge</option>
          {Object.entries(ChallengeType).map(a => (
            <option key={a[1]} value={a[1]}>{a[0]}</option>
          ))}
        </select>
      </div>

      <div className="form-field">
        <label>Company</label>
        <select className="form-input select" onChange={(e) => changeValue(f => f.company_id = e.target.value || undefined)} value={form.company_id}>
          <option value={""}>Select company</option>
          {companies.map(a => (
            <option key={a.id} value={a.id}>{a.name}</option>
          ))}
        </select>
      </div>

      {form.company_id &&
        <div className="form-field">
          <label>Departments</label>
          <select className="form-input select"
                  onChange={(e) => changeValue(f => {
                    const ids = [];
                    for (var i = 0, l = e.target.options.length; i < l; i++) {
                      if (e.target.options[i].selected && e.target.options[i].value) {
                        ids.push(e.target.options[i].value);
                      }
                    }
                    f.department_ids = ids;
                    console.log('Change department ', ids);
                  })}
                  value={form.department_ids}
                  multiple={true}>
              <option value="">Select departments</option>
            {(companies.find(c => c.id === form.company_id)?.departments || []).map(a => (
              <option key={a.id} value={a.id} selected={form.department_ids.includes(a.id)}>{a.name}</option>
            ))}
          </select>
        </div>
      }

      <div className="form-field">
        <label>Goal</label>
        <select className="form-input select" onChange={(e) => changeValue(f => f.challenge_goal_id = e.target.value)} value={form.challenge_goal_id}>
          <option>Select Goal</option>
          {goals.map(a => (
            <option key={a.id} value={a.id}>{a.getName()}</option>
          ))}
        </select>
      </div>

      <div className="form-field">
        <label>Reward</label>
        <select className="form-input select" onChange={(e) => changeValue(f => f.challenge_reward_id = e.target.value)} value={form.challenge_reward_id}>
          <option>Select Reward</option>
          {rewards.map(a => (
            <option key={a.id} value={a.id}>{a.name}</option>
          ))}
        </select>
      </div>

      <div className="form-field">
        <label>Condition</label>
        <select className="form-input select" onChange={(e) => changeValue(f => f.challenge_reward_condition_id = e.target.value)} value={form.challenge_reward_condition_id}>
          <option>Select Condition</option>
          {conditions.map(a => (
            <option key={a.id} value={a.id}>{a.getName()}</option>
          ))}
        </select>
      </div>


      <div className="form-field">
        <label>Name</label>
        <input type="string" onChange={e => changeValue(f => f.name = e.target.value)} name="is_active" value={form.name} />
      </div>

      <div className="form-field">
        <label>Description</label>
        <textarea onChange={e => changeValue(f => f.description = e.target.value)} name="name" value={form.description} />
      </div>

      <div className="form-field">
        <label>Image</label>
        <input type="string" onChange={e => changeValue(f => f.image = e.target.value)} name="is_active" value={form.image} />
      </div>

      <div className="form-field">
        <label>Start</label>
        <DatePicker
          selected={form.started_at}
          onChange={e => changeValue(f => f.started_at = e || undefined)}
          dateFormat='dd/MM/yyyy'
          placeholderText="Enter date"
          showYearDropdown
        />
      </div>

      <div className="form-field">
        <label>End</label>
        <DatePicker
          selected={form.ended_at}
          onChange={e => changeValue(f => f.ended_at = e || undefined)}
          dateFormat='dd/MM/yyyy'
          placeholderText="Enter date"
          showYearDropdown
          />
      </div>

      <div className="form-field">
        <label>Duration</label>
        <input type="number" onChange={e => changeValue(f => f.duration = +e.target.value)} name="is_active" value={form.duration} />
      </div>

      <div className="form-field">
        <label>Is template</label>
        <input type="checkbox" onChange={e => changeValue(f => f.is_template = e.target.checked)} name="is_active" checked={form.is_template} />
      </div>


      <button onClick={saveReward}>Save</button>
    </div>
  );
};
export default ChallengeFormComponent;
