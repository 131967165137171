import fetchService from "../../common/services/fetch.service";
import {
  Journey,
  JourneyFilter,
  JourneyForm,
  JourneySession,
  JourneySessionForm,
  SessionActivityForm
} from "../models/Journey";
import Pagination from "../../common/models/Pagination";
import formService from "../../common/services/form.service";

class JourneyService {

  createJourney(form: JourneyForm): Promise<Journey> {
    return fetchService.fetchApi<Journey>(`/api/admin/journey`, {
      method: "POST",
      body: formService.convertObjectIntoFormData(form),
      headers:{}
    });
  }

  updateJourney(id: string, form: JourneyForm): Promise<Journey> {
    return fetchService.fetchApi<Journey>(`/api/admin/journey/${id}`, {
      method: "PATCH",
      body: formService.convertObjectIntoFormData(form),
      headers:{}
    });
  }

  getJourney(id: string): Promise<Journey | null> {
    return fetchService.fetchApi<Journey>(`/api/admin/journey/${id}`, {
      method: "GET",
    });
  }

  getJourneys(filter: JourneyFilter, pagination: Pagination | null = null) {
    const search = fetchService.objectToPath(filter);
    const paging = fetchService.objectToPath(pagination);
    return fetchService.fetchApi<Journey[]>(`/api/admin/journeys?${search}&${paging}`, {
      method: "GET"
    }).then(result => {
      return result.map(data => new Journey(data));
    });
  }

  deleteJourney(id: string) {
    return fetchService.fetchApi<Journey[]>(`/api/admin/journey/${id}`, {
      method: "DELETE"
    });
  }

  getJourneySession(id: string, sessionId: string): Promise<JourneySession | null> {
    return fetchService.fetchApi<JourneySession>(`/api/admin/journey/${id}/session/${sessionId}`, {
      method: "GET",
    });
  }

  addJourneySession(id: string, form: JourneySessionForm) {
    return fetchService.fetchApi<Journey>(`/api/admin/journey/${id}/session`, {
      method: 'POST',
      body: JSON.stringify(form)
    });
  }

  updateJourneySession(journey_id: string, session_id: string, form: JourneySessionForm) {
    return fetchService.fetchApi<Journey>(`/api/admin/journey/${journey_id}/session/${session_id}`, {
      method: 'PATCH',
      body: JSON.stringify(form)
    });
  }

  addSessionActivity(id: string, sessionId: string, activityId: string, form: SessionActivityForm) {
    return fetchService.fetchApi<JourneySession>(`/api/admin/journey/${id}/session/${sessionId}/activity/${activityId}`, {
      method: 'POST',
      body: JSON.stringify(form)
    });
  }

  removeSessionActivity(id: string, sessionId: string, activityId: string) {
    return fetchService.fetchApi<JourneySession>(`/api/admin/journey/${id}/session/${sessionId}/activity/${activityId}`, {
      method: 'DELETE',
    });
  }

  deleteJourneySession(id: string, session_id: string) {
    return fetchService.fetchApi<Journey>(`/api/admin/journey/${id}/session/${session_id}`, {
      method: "DELETE"
    });
  }

}

const journeyService = new JourneyService();
export default journeyService;
