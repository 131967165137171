import "./RateComponent.scss";
import {useEffect, useState} from "react";
import {UserSessionActivity} from "../../../journeys/models/UserJourney";
import userJourneyService from "../../../journeys/services/user-journey.service";
import {UserSessionActivityFilter} from "../../../journeys/models/UserJourneyFilters";

const RateComponent = () => {
  const [rates, setRates] = useState<UserSessionActivity[]>([]);

  useEffect(() => {
    const filter = new UserSessionActivityFilter(true);
    userJourneyService.filterUserActivities(filter).then(setRates);
  }, []);

  return (
    <div>
      <div className="table full-width">
        <div className="row table-header">
          <div className="cell">Activity Name</div>
          <div className="cell">Rate</div>
          <div className="cell">Completed At</div>
          <div className="cell">Relon</div>
        </div>

        {rates.map(a => (
          <div className="row" key={a.id}>
            <div className="cell">{a.activity.name}</div>
            <div className="cell">{a.rate}</div>
            <div className="cell">{a.completed_at}</div>
          </div>
        ))}

        <div className="row table-footer">
        </div>

      </div>
    </div>
  );
};

export default RateComponent;
