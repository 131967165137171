import userStoreService from "../../common/services/user-store.service";
import ReflectionQuestionsList from "../components/ReflectionQuestionsList/ReflectionQuestionsList";
import ReflectionQuestionsFormComponent from "../components/ReflectionQuestionsForm/ReflectionQuestionsFormComponent";

const routes = [
  {
    path: '/reflections',
    component: ReflectionQuestionsList,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
  {
    path: '/reflection/:id',
    component: ReflectionQuestionsFormComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
  {
    path: '/reflection',
    component: ReflectionQuestionsFormComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
];

export default routes;
