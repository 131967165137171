import React from "react";
import {Link} from "react-router-dom";
import "./HeaderComponent.scss";
import userStoreService from "../../services/user-store.service";

function HeaderComponent() {
  const user = userStoreService.getUser();
  return (
    <header className="app">
      {user && (
        <div className="menu">
          <Link to={'/activities'}>Activities</Link>
          <Link to={'/journeys'}>Journeys</Link>
          <Link to={'/features'}>Feature</Link>
          <Link to={'/feature/categories'}>Feature categories</Link>
          <Link to={'/benefits'}>Benefit</Link>
          <Link to={'/rates'}>Rates</Link>
          <Link to={'/referrals'}>Referrals</Link>
          <Link to={'/companies'}>Companies</Link>
          <Link to={'/challenges'}>Challenges</Link>
          <Link to={'/reflections'}>Reflections</Link>
          <Link to={'/user/activities'}>User Activities</Link>
          <Link to={'/demo'}>Demo</Link>
          <Link to={'/notifications'}>Notifications</Link>
        </div>
      )}
    </header>
  );
}

export default HeaderComponent;
