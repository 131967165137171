import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import reflectionService from "../../services/reflection.service";
import {ReflectionQuestion} from "../../models/Reflection";

const ReflectionQuestionsList = () => {
  const [reflections, setReflections] = useState<ReflectionQuestion[]>([]);

  useEffect(() => {
    reflectionService.getReflections().then(setReflections);
  }, []);

  return (
    <div>
      <Link to={`/reflection`}>Create</Link>

      <div className="table full-width">
        <div className="row table-header">
          <div className="cell">Questions</div>
          <div className="cell">Category</div>
          <div className="cell">Created At</div>
          <div className="cell">Actions</div>
        </div>

        {reflections.map(c => (
          <div className="row" key={c.id}>
            <div className="cell">{c.question}</div>
            <div className="cell">{c.category}</div>
            <div className="cell">{c.created_at}</div>
            <div className="cell cell-actions">
              <Link to={`/reflection/${c.id}`}>Edit</Link>
            </div>
          </div>
        ))}

        <div className="row table-footer">
        </div>

      </div>
    </div>
  );
};

export default ReflectionQuestionsList;
