import {ActivityBenefit} from "./Benefit";

export enum ActivityType {
  Assessment = 'assessment',
  SoundTherapy = 'sound-therapy',
  Exercise = 'exercise',
}

export class Activity {
  id!: string;
  name!: string;
  image!: string;
  image_path!: string;
  description!: string;
  type!: ActivityType;
  is_public!: boolean; // coming soon
  is_premium!: boolean;
  is_active!: boolean;
  benefits: ActivityBenefit[] = [];
  time_estimation?: number;

  constructor(props: any) {
    Object.assign(this, props);
  }

  getLink() {
    return `/app/activity/${this.id}`;
  }


}

