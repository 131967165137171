import userStoreService from "../../common/services/user-store.service";
import CompanyListComponent from "../components/CompanyListComponent/CompanyListComponent";
import CompanyFormComponent from "../components/CompanyFormComponent/CompanyFormComponent";

const routes = [
  {
    path: '/companies',
    component: CompanyListComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
  {
    path: '/company/:id',
    component: CompanyFormComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
  {
    path: '/company',
    component: CompanyFormComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
];

export default routes;
