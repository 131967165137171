import authRoutes from "../modules/auth/routes";
import journeyRoutes from "../modules/journeys/routes";
import activityRoutes from "../modules/activity/routes";
import featuresRoutes from "../modules/features/routes";
import rateRoutes from "../modules/rate/routes";
import referralRoutes from "../modules/referral/routes";
import companyRoutes from "../modules/company/routes";
import challengesRoutes from "../modules/challenges/routes";
import userActivityRoutes from "../modules/admin/routes";
import reflectionRoutes from "../modules/reflections/routes";
import demoRoutes from "../modules/demo/routes";
import notificationsRoutes from "../modules/notifications/routes";


const index = [
  ...referralRoutes,
  ...rateRoutes,
  ...featuresRoutes,
  ...activityRoutes,
  ...journeyRoutes,
  ...userActivityRoutes,
  ...companyRoutes,
  ...challengesRoutes,
  ...reflectionRoutes,
  ...demoRoutes,
  ...notificationsRoutes,
  ...authRoutes,
];

export default index;
