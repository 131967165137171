import {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import {Journey, JourneyForm, JourneySession, JourneySessionForm} from "../../models/Journey";
import journeyService from "../../services/journey.service";
import {Link} from "react-router-dom";

function JourneyComponent() {
  const [journey, setJourney] = useState<Journey | null>();
  const [sessionForm, setSessionForm] = useState<JourneySessionForm>(new JourneySessionForm());
  const { id } = useParams<any>();

  useEffect(() => {
    journeyService.getJourney(id).then(setJourney);
  }, [id]);

  const updateForm = (callback: (form: JourneySessionForm) => void) => {
    const form = {...sessionForm} as JourneySessionForm;
    callback(form);
    setSessionForm(form);
  };

  const updateJourney = (field: 'is_active' | 'is_public' | 'with_certificate') => {
    console.log('update journey');
    if (journey) {
      const data = {
        name: journey.name,
        description: journey.description,
        is_active: journey.is_active,
        is_public: journey.is_public,
        mail_list_id: undefined,
        with_certificate: journey.with_certificate
      } as JourneyForm;
      data[field] = ! journey[field];
      journeyService.updateJourney(journey.id, data).then(journey => {
        setJourney(journey);
      });
    }
  };
  const uploadImage = (file: File | undefined) => {
    if (file && journey) {
      const data = {
        name: journey.name,
        description: journey.description,
        is_active: journey.is_active,
        is_public: journey.is_public,
        with_certificate: journey.with_certificate,
        mail_list_id: undefined,
        image: file
      } as JourneyForm;
      journeyService.updateJourney(journey.id, data).then(journey => {
        setJourney(journey);
      });
    }
  };


  const addSession = () => {
    journeyService.addJourneySession(id, sessionForm).then(j => {
      setJourney(j);
      setSessionForm(new JourneySessionForm());
    });
  };
  const removeSession = (s: JourneySession) => {
    journeyService.deleteJourneySession(id, s.id).then(j => {
      setJourney(j);
    });
  };

  return (
    <div>
      JourneyComponent
      <p>NAME: {journey?.name}</p>
      <p>{journey?.description}</p>
      <div className="form-field">
        <label>Is Active</label>
        <input type="checkbox" onChange={e => updateJourney('is_active')} name="is_active" checked={journey?.is_active} />
      </div>

      <div className="form-field">
        <label>Image</label>
        <input type="file" onChange={e => uploadImage(e.target.files ? e.target.files[0] : undefined)} name="image" />
      </div>

      <div className="form-field">
        <label>Is Public</label>
        <input type="checkbox" onChange={e => updateJourney('is_public')} name="is_public" checked={journey?.is_public} />
      </div>

      <div className="form-field">
        <label>With Certificate</label>
        <input type="checkbox" onChange={e => updateJourney('with_certificate')} name="is_public" checked={journey?.with_certificate} />
      </div>

      <h3>Sessions</h3>
      <div className="table">
        <div className="row header">
          <div className="cell">Name</div>
          <div className="cell">Description</div>
          <div className="cell">Video</div>
          <div className="cell">Duration(min)</div>
          <div className="cell">Can do same day</div>
          <div className="cell">Action</div>
        </div>

        {(journey?.sessions || []).map(s => (
          <div className="row" key={s.id}>
            <div className="cell">{s.name}</div>
            <div className="cell">{s.description}</div>
            <div className="cell">{s.video}</div>
            <div className="cell">{s.duration_minutes}</div>
            <div className="cell">{s.can_same_day ? "X" : ""}</div>
            <div className="cell">
              <Link to={`/journey/${id}/session/${s.id}`}>Edit</Link>
              <button onClick={() => removeSession(s)}>Delete</button>
            </div>
          </div>
        ))}

        <div className="row">
          <div className="cell">
            <div className="form-field">
              <input type="text" onChange={e => updateForm((f) => f.name = e.target.value)} name="name" value={sessionForm.name} />
            </div>
          </div>
          <div className="cell">
            <div className="form-fieluploadImaged">
              <textarea onChange={e => updateForm((f) => f.description = e.target.value)} name="name" value={sessionForm?.description} />
            </div>
          </div>
          <div className="cell">
            <div className="form-field">
              <input type="text" onChange={e => updateForm((f) => f.video = e.target.value)} name="name" value={sessionForm?.video} />
            </div>
          </div>
          <div className="cell">
            <div className="form-field">
              <input type="text" onChange={e => updateForm((f) => f.duration_minutes = +e.target.value)} name="duration_minutes" value={sessionForm?.duration_minutes} />
            </div>
          </div>
          <div className="cell">
            <div className="form-field">
              <input type="checkbox" onChange={e => updateForm((f) => f.can_same_day = e.target.checked)} name="name" checked={sessionForm?.can_same_day} />
            </div>
          </div>
          <div className="cell">
            <button onClick={addSession}>Save</button>
          </div>
        </div>

      </div>


    </div>
  );
}

export default JourneyComponent;

