export class ReferralCodeForm {
  code: string;
  journey_id?: string;
  department_id?: string;

  constructor(code: string, journey_id?: string, department_id?: string) {
    this.code = code;
    this.journey_id = journey_id;
    this.department_id = department_id;
  }
}
