import {useState} from "react";
import {NotificationForm, NotificationType} from "../models/NotificationForm";
import notificationService from "../services/notification.service";

const SendNotificationFormComponent = () => {
  const [notificationForm, setNotificationForm] = useState<NotificationForm>(new NotificationForm());
  const [errors, setErrors] = useState<any>({});

  const changeValue = (callback: (form: NotificationForm) => void) => {
    const form = {...notificationForm};
    callback(form);
    setNotificationForm(form);
  };

  const sendNotification = () => {
    if (!notificationForm.user_id && notificationForm.reciepent === 'single') {
      errors.user_id = 'Need to define user'
    }
    notificationService.sendNotification(notificationForm).then(() => {
      setNotificationForm(new NotificationForm());
    }).catch(setErrors);
  };

  return (
    <div>
      <div className="form-field">
        <label>TO whom</label>
        <select className="form-input select" onChange={(e) => changeValue(f => f.reciepent = e.target.value as 'single' | 'all')} value={notificationForm.reciepent}>
          <option value={'single'}>single</option>
          <option value={'all'}>all</option>
        </select>
      </div>

      <div className="form-field">
        <label>Notification Type</label>
        <select onChange={e => changeValue(f => f.notification_type = e.target.value as NotificationType)} value={notificationForm.notification_type}>
          {Object.entries(NotificationType).map(type => (
            <option value={type[1]} key={type[1]}>{type[0]}</option>
          ))}
        </select>
      </div>

      <div className="form-field">
        <label>Image</label>
        <input type="text" onChange={e => {changeValue(f => f.notification_image = e.target.value)}} name="notification_image" value={notificationForm?.notification_image} />
        <span>{errors.hasOwnProperty('notification_image') ? errors.notification_image : ''}</span>
      </div>

      {notificationForm.reciepent === 'single' && <div className="form-field">
        <label>User id</label>
        <input type="text" onChange={e => {changeValue(f => f.user_id = e.target.value)}} name="user_id" value={notificationForm?.user_id} />
        <span>{errors.hasOwnProperty('user_id') ? errors.user_id : ''}</span>
      </div>}

      {notificationForm.notification_type === NotificationType.VersionUpdate && <div className="form-field">
          <label>version</label>
          <input type="text" onChange={e => {changeValue(f => f.version = e.target.value)}} name="version" value={notificationForm?.version} />
          <span>{errors.hasOwnProperty('version') ? errors.version : ''}</span>
      </div>}


      {notificationForm.notification_type === NotificationType.VersionUpdate && <div className="form-field">
          <label>Update text</label>
          <textarea onChange={e => {changeValue(f => f.update_text = e.target.value)}} name="update_text" value={notificationForm?.update_text} />
          <span>{errors.hasOwnProperty('update_text') ? errors.name : ''}</span>
      </div>}


      <button onClick={sendNotification}>Send</button>
    </div>
  );
};

export default SendNotificationFormComponent;
