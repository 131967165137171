import fetchService from "../../../common/services/fetch.service";
import {useState} from "react";

const DemoComponent = () => {
  const [file, setFile] = useState<File>()
  const [clean, setClean] = useState<boolean>(true);

  function handleChange(event: any) {
    setFile(event.target.files[0])
  }

  function handleSubmit() {
    if (!file) {
      return;
    }
    console.log('submit');

    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file.name);
    fetchService.fetchApi(`/api/admin/demo/upload?clean=${clean ? 1 : 0}`, {
      method: "POST",
      body: formData,
      headers: {}
    }).then((response) => {
      console.log(response);
    });

  }

  return (
    <div>
      <h1>Upload demo data</h1>
      <div className="form-field">
        <label>Clean data</label>
        <input type="checkbox" onChange={e => setClean(e.target.checked)} name="is_active" checked={clean} />
      </div>
      <input type="file" onChange={handleChange} />
      <button type="submit" onClick={handleSubmit}>Upload</button>
    </div>
  );
};
export default DemoComponent;
