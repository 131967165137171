import {ActivityForm, ExerciseCommandForm, ExerciseForm, ExerciseTypes} from "../../models/ActivityForm";
import {ExerciseCommandType} from "../../models/Exercise";

export interface ExerciseFormComponentProps {
  form: ExerciseForm;
  onChange: (callback: (form: ActivityForm) => void) => void;
  // onChange: (field: string, data: any) => void;
}

const ExerciseFormComponent = (props: ExerciseFormComponentProps) => {
  const changeCommandValue = (commandsIdx: number, callback: (form: ExerciseCommandForm) => void) => {
    const form = {...props.form};
    callback(form.commands[commandsIdx]);
    props.onChange(f => (f as ExerciseForm).commands = form.commands);
  };
  const addCommand = () => {
    const form = {...props.form};
    form.commands.push(new ExerciseCommandForm());
    props.onChange(f => (f as ExerciseForm).commands = form.commands);
  };

  const deleteCommand = (idx: number) => {
    const form = {...props.form};
    form.commands.splice(idx);
    props.onChange(f => (f as ExerciseForm).commands = form.commands);
  };

  return (
    <div>
      <p>Exercise</p>

      <div className="form-field">
        <label>Exercise type</label>
        <select id="activity-type" className="form-input select" onChange={(e) => props.onChange(c => (c as ExerciseForm).exercise_type = e.target.value)} value={(props.form as ExerciseForm).exercise_type}>
          <option>Choose type</option>
          {Object.entries(ExerciseTypes).map(a => (
            <option key={a[1]} value={a[1]}>{a[0]}</option>
          ))}
        </select>
      </div>

      {(props.form.commands || []).map((cmd, idx) => (
        <div className="form-group-row" key={idx}>
          <div className="form-field">
            <label>Command type</label>
            <select id="activity-type" className="form-input select" onChange={(e) => changeCommandValue(idx, c => c.type = e.target.value)} value={cmd.type}>
              <option>Choose type</option>
              {Object.entries(ExerciseCommandType).map(a => (
                <option key={a[1]} value={a[1]}>{a[0]}</option>
              ))}
            </select>
          </div>

          {[ExerciseCommandType.Wait, ExerciseCommandType.BreathOut, ExerciseCommandType.BreathIn, ExerciseCommandType.HolAfterBreathIn, ExerciseCommandType.HoldAfterBreathOut].includes(cmd.type as ExerciseCommandType) && (
            <div className="form-field">
              <label>Delay (ms)</label>
              <input type="number" onChange={e => changeCommandValue(idx, c => c.delayMs = +e.target.value)} name="file" value={cmd.delayMs} />
            </div>
          )}

          {cmd.type === ExerciseCommandType.RepeatStart && (
            <div className="form-field">
              <label>Repeat times</label>
              <input type="number" onChange={e => changeCommandValue(idx, c => c.repeat = +e.target.value)} name="repeat" value={cmd.repeat} />
            </div>
          )}

          {cmd.type && cmd.type !== ExerciseCommandType.Wait && (
            <div className="form-field">
              <label>Instruction text</label>
              <textarea onChange={e => {changeCommandValue(idx, c => c.instruction = e.target.value)}} name="name" value={cmd.instruction} />
            </div>
          )}

          {cmd.type === ExerciseCommandType.ShowText && (
            <div className="form-field">
              <label>Image path</label>
              <input type="string" onChange={e => changeCommandValue(idx, c => c.image = e.target.value)} name="image" value={cmd.image} />
            </div>
          )}

          <div>
            <button onClick={() => {deleteCommand(idx)}}>Delete</button>
          </div>
        </div>
      ))}

      <button onClick={addCommand}>Add Command</button>
    </div>
  );
};

export default ExerciseFormComponent;
