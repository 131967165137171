import userStoreService from "../../common/services/user-store.service";
import UserActivitiesListComponent from "../components/UserActivitiesListComponent/UserActivitiesListComponent";

const routes = [
  {
    path: '/user/activities',
    component: UserActivitiesListComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
];

export default routes;
