export class ReflectionQuestion {
  id!: string;
  question!: string;
  category!: string;
  created_at!: string;
}

export class ReflectionQuestionForm {
  question: string = '';
  category: string = '';

  constructor(props: any = {}) {
    Object.assign(this, props);
  }
}
