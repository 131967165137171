import "./BenefitFormComponent.scss";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {BenefitForm} from "../../models/Benefit";
import activityService from "../../services/activity.service";
import {history} from "../../../../components/WeVoiceRouter/WeVoiceRouter";

const BenefitFormComponent = () => {
  const [form, setForm] = useState<BenefitForm>(new BenefitForm());
  const {id} = useParams<{id?: string}>();

  useEffect(() => {
    if (id) {
      activityService.getBenefit(+id).then((benefit) => {
        setForm(BenefitForm.fromBenefit(benefit));
      });
    }
  }, [id]);

  const saveBenefit = () => {
    const saveResult = id ? activityService.updateBenefit(+id, form) : activityService.createBenefit(form);
    saveResult.then(() => {
      history.push("/benefits");
    });
  };

  const changeValue = (callback: (form: BenefitForm) => void) => {
    const f = {...form};
    callback(f)
    setForm(f);
  };

  return (
    <div>
      <div className="form">

        <div className="form-field">
          <label>Name</label>
          <input type="text" onChange={e => {changeValue(f => f.name = e.target.value)}} name="name" value={form?.name || ''} />
        </div>

        <div className="form-field">
          <label>Is Active</label>
          <input type="checkbox" onChange={e => {changeValue(f => f.is_active = e.target.checked)}} name="is_active" checked={form?.is_active !== undefined && form.is_active} />
        </div>

        <div className="form-field">
          <label>Icon</label>
          <input type="text" onChange={e => {changeValue(f => f.icon = e.target.value)}} name="icon" value={form?.icon || ''} />
        </div>

        <div className="form-field">
          <label>Image</label>
          <input type="text" onChange={e => {changeValue(f => f.image = e.target.value)}} name="image" value={form?.image || ''} />
        </div>

        <button onClick={saveBenefit}>Save activity</button>

      </div>
    </div>
  );
};

export default BenefitFormComponent;
