import "./CompanyFormComponent.scss";
import {useCallback, useEffect, useState} from "react";
import {Company, CompanyForm} from "../../models/Company";
import {useHistory, useParams} from "react-router-dom";
import companyService from "../../services/company.service";
import {DepartmentForm} from "../../models/Department";

const CompanyFormComponent = () => {
  const {id} = useParams<{id?: string}>();
  const history = useHistory();
  const [form, setForm] = useState(new CompanyForm());
  const [depForm, setDepForm] = useState(new DepartmentForm());
  const [company, setCompany] = useState<Company | null>();

  const loadCompany = useCallback(() => {
    if (id) {
      companyService.getCompany(id).then(company => {
        setCompany(company);
        setForm(new CompanyForm(company));
      });
    }
  }, [id]);

  useEffect(() => {
    loadCompany();
  }, [loadCompany]);

  const changeValue = (callback: (form: CompanyForm) => void) => {
    const f = {...form};
    callback(f);
    setForm(f);
  };

  const saveCompany = () => {
    (id ? companyService.updateCompany(id, form) : companyService.createCompany(form)).then(() => {
      history.push("/companies");
    }).catch((error) => {
      console.log('Could not save company', id, form, error);
    });
  };

  const changeDepValue = (callback: (form: DepartmentForm) => void) => {
    const f = {...depForm};
    callback(f);
    setDepForm(f);
  };
  const addDepartment = () => {
    if (id) {
      companyService.createDepartment(id, depForm).then(loadCompany);
    }
  };

  return (
    <div className="form">
      Company Form
      <div className="form-field">
        <label>Name</label>
        <input type="text" onChange={e => {changeValue(f => f.name = e.target.value)}} name="name" value={form?.name} />
      </div>

      <div className="form-field">
        <label>Description</label>
        <textarea onChange={e => {changeValue(f => f.description = e.target.value)}} name="description" value={form.description} />
      </div>

      <div className="form-field">
        <label>Admin Email</label>
        <input type={"email"} onChange={e => {changeValue(f => f.email = e.target.value)}} name="email" value={form.email} />
      </div>

      <div className="form-field">
        <label>Logo</label>
        <input type={"file"} onChange={e => {changeValue(f => f.logo = e.target.files ? e.target.files[0] : undefined)}} name="logo" />
      </div>

      <div className="form-field">
        <label>Is Active</label>
        <input type="checkbox" onChange={e => changeValue(f => f.is_active = e.target.checked)} name="is_active" checked={form.is_active} />
      </div>

      <button onClick={saveCompany}>Save</button>

      {company && (
        <div>
          <div className="table full-width">
            <div className="row table-header">
              <div className="cell">Name</div>
              <div className="cell">Active</div>
              <div className="cell">Created At</div>
              <div className="cell">Actions</div>
            </div>

            {company.departments.map(dep => (
              <div className="row" key={dep.id}>
                <div className="cell">{dep.name}</div>
                <div className="cell">{dep.is_active ? 1 : 0}</div>
                <div className="cell">{dep.created_at}</div>
                <div className="cell">&nbsp;</div>
              </div>
            ))}
          </div>

          <div>
            <div className="form-field">
              <label>Name</label>
              <input type="text" onChange={e => {changeDepValue(f => f.name = e.target.value)}} name="name" value={depForm?.name} />
            </div>

            <div className="form-field">
              <label>Description</label>
              <textarea onChange={e => {changeDepValue(f => f.description = e.target.value)}} name="description" value={depForm.description} />
            </div>

            <div className="form-field">
              <label>Is Active</label>
              <input type="checkbox" onChange={e => changeDepValue(f => f.is_active = e.target.checked)} name="is_active" checked={depForm.is_active} />
            </div>

            <button onClick={() => addDepartment()}>Add department</button>
          </div>
        </div>
      )}
    </div>
  );
};
export default CompanyFormComponent;
