import {useHistory, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import reflectionService from "../../services/reflection.service";
import {ReflectionQuestionForm} from "../../models/Reflection";

const ReflectionQuestionsFormComponent = () => {
  const {id} = useParams<{id?: string}>();
  const history = useHistory();
  const [form, setForm] = useState(new ReflectionQuestionForm());

  useEffect(() => {
    if (id) {
      reflectionService.getReflection(id).then(reflection => {
        setForm(new ReflectionQuestionForm(reflection));
      });
    }
  }, [id]);

  const changeValue = (callback: (form: ReflectionQuestionForm) => void) => {
    const f = {...form};
    callback(f);
    setForm(f);
  };

  const saveReflection = () => {
    (id ? reflectionService.updateReflection(id, form) : reflectionService.createReflection(form)).then(() => {
      history.push("/reflections");
    }).catch((error) => {
      console.log('Could not save reflection', id, form, error);
    });
  };

  return (
    <div className="form">
      Company Form
      <div className="form-field">
        <label>Category</label>
        <input type="text" onChange={e => {changeValue(f => f.category = e.target.value)}} name="name" value={form?.category} />
      </div>

      <div className="form-field">
        <label>Question</label>
        <textarea onChange={e => {changeValue(f => f.question = e.target.value)}} name="description" value={form.question} />
      </div>

      <button onClick={saveReflection}>Save</button>
    </div>
  );
};

export default ReflectionQuestionsFormComponent;
