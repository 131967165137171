import {SocialProviderService} from "./social.provider";

export class FacebookProvider extends SocialProviderService {

  appId = "253704093175108";

  getLoginUrl(redirectUrl: string) {
    return "https://www.facebook.com/v10.0/dialog/oauth?" +
      `client_id=${this.appId}` +
      `&redirect_uri=${redirectUrl}` +
      `&state={state-param}`;
  }

}
