import userStoreService from "../../common/services/user-store.service";
import ActivityComponent from "../components/ActivityComponent/ActivityComponent";
import ActivityListComponent from "../components/ActivityListComponent/ActivityListComponent";
import BenefitListComponent from "../components/BenefitListComponent/BenefitListComponent";
import BenefitFormComponent from "../components/BenefitFormComponent/BenefitFormComponent";

const routes = [
  {
    path: '/activity/:id',
    component: ActivityComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
  {
    path: '/activities',
    component: ActivityListComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
  {
    path: '/activity',
    component: ActivityComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
  {
    path: '/benefit/:id',
    component: BenefitFormComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
  {
    path: '/benefit',
    component: BenefitFormComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
  {
    path: '/benefits',
    component: BenefitListComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
];

export default routes;
