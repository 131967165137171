import "./ChallengeGoalFormComponent.scss";
import {useHistory, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import challengeService from "../../services/challenge.service";
import {ChallengeGoalForm, ChallengeGoalType} from "../../models/ChallengeGoal";

const ChallengeGoalFormComponent = () => {
  const {id} = useParams<{id?: string}>();
  const history = useHistory();
  const [form, setForm] = useState(new ChallengeGoalForm());

  useEffect(() => {
    if (id) {
      challengeService.getGoal(id).then(goal => {
        setForm(new ChallengeGoalForm(goal));
      });
    }
  }, [id]);

  const changeValue = (callback: (form: ChallengeGoalForm) => void) => {
    const f = {...form};
    callback(f);
    setForm(f);
  };

  const saveGoal = () => {
    (id ? challengeService.updateGoal(id, form) : challengeService.createGoal(form)).then(() => {
      history.push("/challenges/goals");
    }).catch((error) => {
      console.log('Could not save condition', id, form, error);
    });
  };

  return (
    <div className="form">
      Challenge Goals Form

      <div className="form-field">
        <label></label>
        <select className="form-input select" onChange={(e) => changeValue(f => f.type = e.target.value)} value={form.type}>
          <option>Type of the challenge</option>
          {Object.entries(ChallengeGoalType).map(a => (
            <option key={a[1]} value={a[1]}>{a[0]}</option>
          ))}
        </select>
      </div>

      <div className="form-field">
        <label>Value</label>
        <input type="number" onChange={e => changeValue(f => f.value = +e.target.value)} name="is_active" value={form.value} />
      </div>

      <div className="form-field">
        <label>Is sequence</label>
        <input type="checkbox" onChange={e => changeValue(f => f.is_sequence = e.target.checked)} name="is_active" checked={form.is_sequence} />
      </div>

      <button onClick={saveGoal}>Save</button>
    </div>
  );
};

export default ChallengeGoalFormComponent;
