import activityService from "modules/activity/services/activity.service";
import {useEffect, useState} from "react";
import {ActivityType} from "../../models/Activity";
import {useParams} from "react-router-dom";
import {ActivityForm, AssessmentForm, ExerciseForm, SoundSessionForm} from "../../models/ActivityForm";
import AssessmentFormComponent from "../AssessmentFormComponent/AssessmentFormComponent";
import SoundSessionFormComponent from "../SoundSessionFormComponent/SoundSessionFormComponent";
import {history} from "../../../../components/WeVoiceRouter/WeVoiceRouter";
import ExerciseFormComponent from "../ExerciseFormComponent/ExerciseFormComponent";
import {FeatureCategory} from "../../../features/models/feature";
import featureService from "../../../features/services/feature.service";
import {ActivityBenefit, BenefitFilter} from "../../models/Benefit";

const ActivityComponent = () => {
  const [form, setForm] = useState<ActivityForm>(new ActivityForm());
  const [categories, setCategories] = useState<FeatureCategory[]>([]);
  const [benefits, setBenefits] = useState<ActivityBenefit[]>([]);
  const {id} = useParams<{id?: string}>();

  useEffect(() => {
    featureService.getFeatureCategories().then(setCategories);
  }, []);

  useEffect(() => {
    const filter = new BenefitFilter(false, undefined, true);
    activityService.getBenefits(filter).then(setBenefits);
  }, []);

  const changeType = (type: ActivityType | string) => {
    let newForm = {...form};
    newForm.type = type as ActivityType;
    switch (type) {
      case ActivityType.Assessment:
        newForm = AssessmentForm.fromForm(newForm)
        break;
      case ActivityType.SoundTherapy:
        newForm = SoundSessionForm.fromForm(newForm)
        break;
      case ActivityType.Exercise:
        newForm = ExerciseForm.fromForm(newForm)
        break;
    }
    console.log('change type', newForm);
    setForm(newForm);
  };

  const changeBenefits = (benefitId: string) => {
    let newForm = {...form};
    let benefitIdx = newForm.benefits.indexOf(benefitId);
    if (benefitIdx >= 0) {
      newForm.benefits = form.benefits.splice(benefitIdx, 1);
    } else {
      newForm.benefits.push(benefitId);
    }
    setForm(newForm);
  };

  const changeValue = (callback: (form: ActivityForm) => void) => {
    let newForm = {...form};
    callback(newForm);
    setForm(newForm);
  };

  const saveActivity = () => {
    console.log('save activity', form);
    (id ? activityService.updateActivity(id, form) : activityService.createActivity(form)).then(a => {
      history.push("/activities");
    });
  };

  useEffect(() => {
    if (id) {
      activityService.getActivity(id).then((activity) => {
        setForm(ActivityForm.fromActivity(activity));
      });
    }
  }, [id]);

  console.log('form', form);
  return (
    <div>
      <div className="form">

        {!id ? (
            <div className="form-field">
              <label>Activity type</label>
              <select id="activity-type" className="form-input select" onChange={(e) => changeType(e.target.value)} value={form?.type}>
                <option>Choose type</option>
                {Object.entries(ActivityType).map(a => (
                  <option key={a[1]} value={a[1]}>{a[0]}</option>
                ))}
              </select>
            </div>
          ) :
          (
            <div>{form?.type}</div>
          )
        }

        <div className="form-field">
          <label>Benefits</label>
          <select id="activity-type" className="form-input select" onChange={(e) => changeBenefits(e.target.value)} value={form?.benefits} multiple={true}>
            <option>Choose benefit</option>
            {benefits.map(benefit => (
              <option key={benefit.id} value={benefit.id}>{benefit.name}</option>
            ))}
          </select>
        </div>

        <div className="form-field">
          <label>Name</label>
          <input type="text" onChange={e => {changeValue(f => f.name = e.target.value)}} name="name" value={form?.name} />
        </div>

        <div className="form-field">
          <label>Code</label>
          <input type="text" onChange={e => {changeValue(f => f.code = e.target.value)}} name="code" value={form?.code} />
        </div>

        <div className="form-field">
          <label>Image</label>
          <input type="text" onChange={e => {changeValue(f => f.image = e.target.value)}} name="name" value={form?.image} />
        </div>

        <div className="form-field">
          <label>Description</label>
          <textarea onChange={e => {changeValue(f => f.description = e.target.value)}} name="description" value={form?.description} />
        </div>

        <div className="form-field">
          <label>Estimated Duration in seconds</label>
          <input type="text" onChange={e => changeValue(f => f.time_estimation = +e.target.value)} name="duration" value={form?.time_estimation} />
        </div>

        <div className="form-field">
          <label>Is Active</label>
          <input type="checkbox" onChange={e => changeValue(f => {console.log('is ative', e.target.checked); f.is_active = e.target.checked;})} name="is_active" checked={form.is_active} />
        </div>

        <div className="form-field">
          <label>Public</label>
          <input type="checkbox" onChange={e => changeValue(f => f.is_public = e.target.checked)} name="is_public" checked={form.is_public} />
        </div>

        <div className="form-field">
          <label>Is Premium</label>
          <input type="checkbox" onChange={e => changeValue(f => f.is_premium = e.target.checked)} name="is_premium" checked={form.is_premium}  />
        </div>

        <div className="form-field">
          <label>Feature Category</label>
          <select className="form-input select" onChange={(e) => changeValue(f => f.feature_category_id = e.target.value)} value={form.feature_category_id}>
            <option>Choose category</option>
            {categories.map(a => (
              <option key={a.id} value={a.id}>{a.name}</option>
            ))}
          </select>
        </div>

        {form?.type === ActivityType.Assessment ?
          (<AssessmentFormComponent form={form as AssessmentForm} onChange={changeValue}/>) :
          form?.type === ActivityType.SoundTherapy ?
            (<SoundSessionFormComponent form={form as SoundSessionForm} onChange={changeValue} />) :
          form?.type === ActivityType.Exercise ?
            (<ExerciseFormComponent form={form as ExerciseForm} onChange={changeValue} />) :
            null
        }

        <button onClick={saveActivity}>Save activity</button>

      </div>
    </div>
  );
};

export default ActivityComponent;
