import LoginComponent from "../components/LoginComponent/LoginComponent";
import userStoreService from "../../common/services/user-store.service";

const routes = [
  {
    path: '/',
    component: LoginComponent,
    redirect: '/journey',
    authCheck: () => userStoreService.getUser() === null
  }
];

export default routes;
