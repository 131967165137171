export enum NotificationType {
  EmotionalTracking='EmotionalTracking',
  VersionUpdate='VersionUpdate'
}

export class NotificationForm {
  reciepent: 'all' | 'single' = 'single'
  user_id?: string;
  notification_type?: NotificationType = NotificationType.EmotionalTracking;
  notification_image?: string;
  version?: string;
  update_text?: string;
}
