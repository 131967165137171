import "./UserActivitiesListComponent.scss";
import React from "react";
import { useEffect, useState } from "react";

import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


import activityService from "../../services/user-activities.service";
import {UserActivitiesFilters, UserActivitiesResponse} from "../../models/UserActivities";
import Pagination from "modules/common/models/Pagination";

const hasRateOptions = [
  { label: '[ALL]' },
  { value: true, label: 'With Rate' },
  { value: false, label: 'Without Rate' }
]
const isDeclinedOptions = [
  { label: '[ALL]' },
  { value: true, label: 'True' },
  { value: false, label: 'False' }
]

const UserActivitiesListComponent = () =>{

  const [userActivities, setUserActivities] = useState<UserActivitiesResponse>();
  const [hasRateValue, setHasRateValue] = useState();
  const [isDeclinedValue, setIsDeclinedValue] = useState();
  const [startDateSelected, setStartDateSelected] = useState();
  const [startDate, setStartDate] = useState(String);
  const [completeDateSelected, setCompleteDateSelected] = useState();
  const [completeDate, setCompleteDate] = useState(String);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  useEffect(() => {

    const filterStartDate = startDate === '' ? undefined : startDate;
    const filterCompleteDate = completeDate === '' ? undefined : completeDate;

    const userActivitiesFilters = new UserActivitiesFilters(hasRateValue, isDeclinedValue, filterStartDate, filterCompleteDate);
    const pagination = new Pagination(currentPage, pageSize);
    console.log(userActivitiesFilters);
    activityService.getUserActivities(userActivitiesFilters, pagination).then(setUserActivities);

  }, [hasRateValue, isDeclinedValue, startDate, completeDate, currentPage, pageSize]);

  const handlePagination = (event: any) => {
    setCurrentPage(Number(event.target.id));
  }
  const pages = [];
  for (let i = 1; i <= Math.ceil(Number(userActivities?.count) / pageSize); i++) {
    pages.push(i);
  }

  const hasRateChange = (hasRateValue:any)=>{
    setHasRateValue(hasRateValue.value);
    setCurrentPage(1);
  }
  const isDeclinedChange = (isDeclinedValue: any) => {
    setIsDeclinedValue(isDeclinedValue.value);
    setCurrentPage(1);
  }
  const onChangeStartDate = (startDateSelected: any) =>{
    setCurrentPage(1);
    setStartDateSelected(startDateSelected);
    let day = startDateSelected.getDate();
    let month = startDateSelected.getMonth()+1;
    let year = startDateSelected.getFullYear();

    const dateISO = year+'-'+month+'-'+day+'T00:00:00';

    setStartDate(dateISO);
  }
  const resetStartDateFilter = (reset: any) => {
    setStartDate("");
    setStartDateSelected(undefined);
    setCurrentPage(1);
  }

  const onChangeCompleteDate = (completeDateSelected: any) => {
    setCurrentPage(1);
    setCompleteDateSelected(completeDateSelected);
    let day = completeDateSelected.getDate();
    let month = completeDateSelected.getMonth() + 1;
    let year = completeDateSelected.getFullYear();

    const dateISO = year + '-' + month + '-' + day + 'T23:59:59';

    setCompleteDate(dateISO);
  }
  const resetCompleteDateFilter = (reset: any) => {
    setCompleteDate("");
    setCompleteDateSelected(undefined);
    setCurrentPage(1);
  }

  const divStyle = {
    width: 'vh',
    padding: '0 0 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  };

  return (
    <div>
      <div style={divStyle}>
        <div>
          Has Rate:
          <Select
            options={hasRateOptions}
            onChange={hasRateChange}
          />
        </div>
        <div>
          Is Declined:
          <Select
            options={isDeclinedOptions}
            onChange={isDeclinedChange}
          />
        </div>
        <div>
          Started At:
          <DatePicker
            selected={startDateSelected}
            onChange={onChangeStartDate}
            dateFormat='dd/MM/yyyy'
            placeholderText="Enter date"
            showYearDropdown
            />
            <div>
              <button onClick={resetStartDateFilter}>Reset Filter</button>
            </div>
        </div>
        <div>
          Completed At:
          <DatePicker
            selected={completeDateSelected}
            onChange={onChangeCompleteDate}
            dateFormat='dd/MM/yyyy'
            placeholderText="Enter date"
            showYearDropdown
          />
          <div>
            <button onClick={resetCompleteDateFilter}>Reset Filter</button>
          </div>
        </div>
      </div>
      <div className="table full-width">
        <div className="row table-header">
          <div className="cell">Email</div>
          <div className="cell">Activity Name</div>
          <div className="cell">Duration</div>
          <div className="cell">Is Declined</div>
          <div className="cell">Started At</div>
          <div className="cell">Completed At</div>
          <div className="cell">Rate</div>
        </div>

        {userActivities?.user_activities.map((a, idx) => (
          <div className="row" key={idx}>
            <div className="cell">{a.email}</div>
            <div className="cell">{a.activity_name}</div>
            <div className="cell">{a.duration}</div>
            <div className="cell">
              {
                a.is_declined===true?'true':
                a.is_declined === false?'false':undefined
              }
            </div>
            <div className="cell">{a.started_at}</div>
            <div className="cell">{a.completed_at}</div>
            <div className="cell">{a.rate}</div>
          </div>
        ))}

        <div className="row table-footer">
          <ul className="pageNumbers">
            {
              pages.map(number => {
                return (
                  <li
                    key={number}
                    id={number.toString()}
                    onClick={handlePagination}
                    className={currentPage === number ? 'active' : undefined}
                  >
                    {number}
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
    </div>
  );
};


export default UserActivitiesListComponent;
