import {SocialProviderService} from "./social.provider";

export class GoogleProvider extends SocialProviderService {

  appId = "27781823236-pgp82rj8julgnm7udfmc9fta64ep9jm2.apps.googleusercontent.com";

  getLoginUrl(redirectUrl: string) {
    return `https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&
scope=email&include_granted_scopes=true&response_type=code&state=state_parameter_passthrough_value&redirect_uri=${redirectUrl}&client_id=${this.appId}`
  }

}
