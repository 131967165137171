import "./ChallengeConditionFormComponent.scss";
import {useHistory, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {ChallengeRewardConditionForm} from "../../models/ChallengeRewardCondition";
import challengeService from "../../services/challenge.service";

const ChallengeConditionFormComponent = () => {
  const {id} = useParams<{id?: string}>();
  const history = useHistory();
  const [form, setForm] = useState(new ChallengeRewardConditionForm());

  useEffect(() => {
    if (id) {
      challengeService.getRewardCondition(id).then(condition => {
        setForm(new ChallengeRewardConditionForm(condition));
      });
    }
  }, [id]);

  const changeValue = (callback: (form: ChallengeRewardConditionForm) => void) => {
    const f = {...form};
    callback(f);
    setForm(f);
  };

  const saveCondition = () => {
    (id ? challengeService.updateRewardCondition(id, form) : challengeService.createRewardCondition(form)).then(() => {
      history.push("/challenges/conditions");
    }).catch((error) => {
      console.log('Could not save condition', id, form, error);
    });
  };

  return (
    <div className="form">
      Condition Form
      <div className="form-field">
        <label>Is Team</label>
        <input type="checkbox" onChange={e => changeValue(f => f.is_for_team = e.target.checked)} name="is_active" checked={form.is_for_team} />
      </div>

      <div className="form-field">
        <label>Is Department</label>
        <input type="checkbox" onChange={e => changeValue(f => f.is_for_department = e.target.checked)} name="is_active" checked={form.is_for_department} />
      </div>

      <div className="form-field">
        <label>Top Winners</label>
        <input type="number" onChange={e => changeValue(f => f.top_amount = +e.target.value)} name="is_active" value={form.top_amount} />
      </div>

      <button onClick={saveCondition}>Save</button>
    </div>
  );
};

export default ChallengeConditionFormComponent;
