import JourneyListComponent from "../components/JourneyListComponent/JourneyListComponent";
import userStoreService from "../../common/services/user-store.service";
import JourneyComponent from "../components/JourneyComponent/JourneyComponent";
import SessionComponent from "../components/SessionComponent/SessionComponent";
import JourneyFormComponent from "../components/JourneyFormComponent/JourneyFormComponent";

const routes = [
  {
    path: '/journey/:id/session/:sessionId',
    component: SessionComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
  {
    path: '/journey/:id',
    component: JourneyComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
  {
    path: '/journeys',
    component: JourneyListComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
  {
    path: '/journey',
    component: JourneyFormComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
];

export default routes;
