export class ChallengeReward {
  id!: string;
  name!:string;
  description!:string;
  image!:string;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;

  constructor(props: any = {}) {
    Object.assign(this, props);
  }
}

export class ChallengeRewardFilter {}

export class ChallengeRewardForm {
  name!:string;
  description!:string;
  image!:string;

  constructor(props: any = {}) {
    Object.assign(this, props);
  }
}
