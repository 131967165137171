import {Redirect, Route, Switch} from "react-router-dom";
import React from "react";


function RouteListComponent(props: {routes: any[]} ) {
  return (
    <Switch>
      {props.routes.map((route, i) => (
        <Route
            key={i}
            path={route.path}
            render={props => (
              (!route.authCheck || route.authCheck() ?
                <route.component /> :
                  <Redirect to={route.redirect} />
              )
            )}
        />
      ))}
    </Switch>
  );
}

export default RouteListComponent;
