import {Activity} from './Activity';

export class SoundTherapy extends Activity {
  // id?: string;
  // name?: string;
  // description?: string;
  // type?: string;
  // image?: string;
  isHeadphoneNeeded = true;
  link?: string;
  file_path!: string;
  video_path!: string;
  duration_seconds!: boolean;
  has_voice!: boolean;

  constructor(props: any) {
    super(props);
    Object.assign(this, props);
  }

  // getLink() {
  //   return `/app/sound-therapy/${this.id}`;
  // }
}
