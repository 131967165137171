import "./ChallengeRewardList.scss";
import {useEffect, useState} from "react";
import challengeService from "../../services/challenge.service";
import {Link} from "react-router-dom";
import {ChallengeReward, ChallengeRewardFilter} from "../../models/ChallengeReward";
import ChallengeMenuComponent from "../ChallengeMenuComponent";

const ChallengeRewardList = () => {
  const [reward, setReward] = useState<ChallengeReward[]>([]);

  useEffect(() => {
    challengeService.getRewards(new ChallengeRewardFilter()).then(setReward);
  }, []);

  return (
    <div>
      <ChallengeMenuComponent />

      <Link to={`/challenges/reward`}>Create</Link>

      <div className="table full-width">
        <div className="row table-header">
          <div className="cell">Name</div>
          <div className="cell">Created At</div>
          <div className="cell">Actions</div>
        </div>

        {reward.map(c => (
          <div className="row" key={c.id}>
            <div className="cell">{c.name}</div>
            <div className="cell">{c.created_at}</div>
            <div className="cell cell-actions">
              <Link to={`/challenges/reward/${c.id}`}>Edit</Link>
            </div>
          </div>
        ))}

        <div className="row table-footer">
        </div>

      </div>
    </div>
  );
};
export default ChallengeRewardList;
