import './CompanyListComponent.scss';
import {useEffect, useState} from "react";
import {Company, CompanyFilter} from "../../models/Company";
import companyService from "../../services/company.service";
import {Link} from "react-router-dom";

const CompanyListComponent = () => {
  const [companies, setCompanies] = useState<Company[]>([]);

  useEffect(() => {
    companyService.getCompanies(new CompanyFilter()).then(setCompanies);
  }, []);

  return (
    <div>
      <Link to={`/company`}>Create</Link>

      <div className="table full-width">
        <div className="row table-header">
          <div className="cell">Name</div>
          <div className="cell">Active</div>
          <div className="cell">Created At</div>
          <div className="cell">Departments</div>
          <div className="cell">Actions</div>
        </div>

        {companies.map(c => (
          <div className="row" key={c.id}>
            <div className="cell">{c.name}</div>
            <div className="cell">{c.is_active ? 1 : 0}</div>
            <div className="cell">{c.created_at}</div>
            <div className="cell">{c.departments.length}</div>
            <div className="cell cell-actions">
              <Link to={`/company/${c.id}`}>Edit</Link>
            </div>
          </div>
        ))}

        <div className="row table-footer">
        </div>

      </div>
    </div>
  );
};
export default CompanyListComponent;
