import "./ChallengeGoalList.scss";
import {useEffect, useState} from "react";
import challengeService from "../../services/challenge.service";
import {Link} from "react-router-dom";
import {ChallengeGoal, ChallengeGoalFilter} from "../../models/ChallengeGoal";
import ChallengeMenuComponent from "../ChallengeMenuComponent";

const ChallengeGoalList = () => {
  const [goals, setGoals] = useState<ChallengeGoal[]>([]);

  useEffect(() => {
    challengeService.getGoals(new ChallengeGoalFilter()).then(setGoals);
  }, []);

  return (
    <div>
      <ChallengeMenuComponent />

      <Link to={`/challenges/goal`}>Create</Link>

      <div className="table full-width">
        <div className="row table-header">
          <div className="cell">Type</div>
          <div className="cell">Value</div>
          <div className="cell">Is Sequence</div>
          <div className="cell">Created At</div>
          <div className="cell">Actions</div>
        </div>

        {goals.map(c => (
          <div className="row" key={c.id}>
            <div className="cell">{c.type}</div>
            <div className="cell">{c.value}</div>
            <div className="cell">{c.is_sequence ? 1 : 0}</div>
            <div className="cell">{c.created_at}</div>
            <div className="cell cell-actions">
              <Link to={`/challenges/goal/${c.id}`}>Edit</Link>
            </div>
          </div>
        ))}

        <div className="row table-footer">
        </div>

      </div>
    </div>
  );
};

export default ChallengeGoalList;
