// eslint-disable-next-line import/no-anonymous-default-export
export default {
  formatDateToString: (date: Date) => {
    const d = new Date(date);
    let day = d.getDate();
    let month = d.getMonth() + 1;
    let year = d.getFullYear();
    const dateFormatted = day + '/' + month + '/' + year;
    return dateFormatted;
  },

  styleDuration: (durationMs: number) => {
    const msInMinute = 60000;
    const msInSecond = 1000;
    const minutes = Math.floor(durationMs / msInMinute);
    const seconds = Math.floor(
      (durationMs - minutes * msInMinute) / msInSecond,
    );

    return (
      (minutes + '').padStart(2, '0') + ':' + (seconds + '').padStart(2, '0')
    );
  },

  getTimeByMinutes: (durationInMinutes: number) => {
    const hours = Math.floor(durationInMinutes/60);
    const minutes = Math.round(durationInMinutes-(hours*60));
    return{hours: hours, minutes: minutes}
  },

  /**
   * Get Summary of time in format HH:MM:SS by seconds
   *
   * Format HH:MM:SS
   * @param seconds
   */
  getFormattedTimeBySeconds: (seconds: number) => {
    const mins = seconds/60;

    const hours = Math.floor(mins/60);
    const minutes = Math.floor(mins-(hours*60));
    const secs = seconds - (hours*60*60) - (minutes*60)
    return ((hours + '').padStart(2, '0') + ':' + (minutes + '').padStart(2, '0') + ':' + (secs + '').padStart(2, '0'));
  },

  /**
   * Format 2023-03-19T23:00:00.000Z
   * @param initialValue
   * @returns
   */
  getJsonDateFormat(initialValue: any){
    const jsonDate = new Date(initialValue).toISOString();
    return jsonDate;
  }
};

