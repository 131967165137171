import {Department} from "./Department";

export class Company {
  id!: string;
  name!: string;
  description!: string;
  is_active!: boolean;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;

  departments: Department[] = [];

  constructor(props: any) {
    Object.assign(this, props);
  }
}


export class CompanyForm {
  email: string = '';
  logo?: File;
  name: string = '';
  description: string = '';
  is_active: boolean = true;

  constructor(props: any = {}) {
    Object.assign(this, props);
  }
}

export class CompanyFilter {
  name?: string;
  is_active?: boolean;
}
