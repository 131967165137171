export class UserActivities {
  email!: string;
  activity_name!: string;
  duration!: number;
  is_declined!: boolean;
  started_at!: any;
  completed_at!: any;
  rate!: number;

  constructor(props: any) {
    return Object.assign(this, props);
  }
}
export class UserActivitiesResponse {
  count!: number;
  user_activities!: UserActivities[];

  constructor(
    count: number,
    user_activities: UserActivities[]
    )
    {
    this.count = count;
    this.user_activities = user_activities;
    }
}

export class UserActivitiesFilters{
  has_rate?: boolean;
  is_declined?: boolean;
  start_date?: any;
  end_date?: any;

  constructor(
    has_rate?: boolean,
    is_declined?:boolean,
    start_date?:any,
    end_date?: any
  )
  {
    this.has_rate = has_rate;
    this.is_declined = is_declined;
    this.start_date = start_date;
    this.end_date = end_date;

  }
}