import {useEffect, useState} from "react";
import {Feature} from "../../models/feature";
import featureService from "../../services/feature.service";
import {Link} from "react-router-dom";

const FeatureListComponent = () => {
  const [features, setFeatures] = useState<Feature[]>([]);

  useEffect(() => {
    featureService.getFeatures().then(setFeatures);
  }, []);

  return (
    <div className="table">

      <Link to={`/feature`}>Create</Link>

      <div className="row header-row">
        <div className="cell">Name</div>
        <div className="cell">Description</div>
        <div className="cell">Frequency</div>
        <div className="cell">Created</div>
        <div className="cell">Updated</div>
        <div className="cell">Actions</div>
      </div>

      {features.map(f => (
        <div className="row">
          <div className="cell">{f.name}</div>
          <div className="cell">{f.description}</div>
          <div className="cell">{f.update_frequency}</div>
          <div className="cell">{f.created_at}</div>
          <div className="cell">{f.updated_at}</div>
          <div className="cell">
            <Link to={`/feature/${f.id}`}>Edit</Link>
          </div>
        </div>
      ))}

    </div>
  );
};

export default FeatureListComponent;
