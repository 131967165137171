export class ChallengeRewardCondition {
  id!: string;
  is_for_department!: boolean;
  is_for_team!: boolean;
  top_amount!: number;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;

  constructor(props: any = {}) {
    Object.assign(this, props);
  }

  getName() {
    return this.is_for_department ? "department" : this.is_for_team ? "team" : this.top_amount;
  }
}

export class ChallengeRewardConditionFilter {}

export class ChallengeRewardConditionForm {
  is_for_department!: boolean;
  is_for_team!: boolean;
  top_amount!: number;

  constructor(props: any = {}) {
    Object.assign(this, props);
  }
}
