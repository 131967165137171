import {useCallback, useEffect, useState} from "react";
import {Activity} from "../../models/Activity";
import activityService from "../../services/activity.service";
import "./ActivityListComponent.scss";
import {Link} from "react-router-dom";
import {SoundTherapy} from "../../models/SoundTherapy";

const ActivityListComponent = () => {
  const [activities, setActivities] = useState<Activity[]>([]);

  const loadActivities = useCallback(() => {
    const filter = {};
    activityService.getActivities(filter).then(setActivities);
  }, []);

  useEffect(() => {
    loadActivities();
  }, [loadActivities]);

  const deleteActivity = (activity: Activity) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Are you sure you want to delete activity?")) {
      activityService.deleteActivity(activity.id).then(() => {
        loadActivities();
      });
    }
  };

  return (
    <div>
      <Link className="button" to={"/activity"}>Create new Activity</Link>

      <div className="table full-width">
        <div className="row table-header">
          <div className="cell">Name</div>
          <div className="cell">Type</div>
          <div className="cell">Active</div>
          <div className="cell">Public</div>
          <div className="cell">Premium</div>
          <div className="cell">Additional</div>
          <div className="cell">Action</div>
        </div>

        {activities.map(a => (
          <div className="row" key={a.id}>
            <div className="cell">{a.name}</div>
            <div className="cell">{a.type}</div>
            <div className="cell">{a.is_active?1:0}</div>
            <div className="cell">{a.is_public?1:0}</div>
            <div className="cell">{a.is_premium?1:0}</div>
            <div className="cell">
                <div>
                  <div>Duration(s): {a.time_estimation}</div>
                  {a instanceof SoundTherapy && (
                    <div>With Voice: {(a as SoundTherapy).has_voice ? 1 : 0}</div>
                  )}
                </div>
            </div>
            <div className="cell action">
              <a href={`/activity/${a.id}`}>Edit</a>
              <a href={`/activity/${a.id}`}>Release</a>
              <button onClick={() => deleteActivity(a)}>Delete</button>
            </div>
          </div>
        ))}

        <div className="row table-footer">
        </div>

      </div>
    </div>
  );
};

export default ActivityListComponent;
