export enum UpdateFrequency {
  Once="once",
  Daily="daily",
  Weekly="weekly",
  Monthly="monthly",
  Yearly="yearly",
}

export enum FeatureType {
  String='string',
  Number='number',
  Smile='smile'
}

export enum FeatureDayPart {
  Any="any",
  Morning="morning",
  Evening="evening",
  Noon="noon",
}

export class Feature {
  id!: string;
  name!: string;
  description!: string;
  category_id!: string;
  day_part!: FeatureDayPart;
  type: FeatureType = FeatureType.String; // todo remove it
  has_integration!: boolean;
  update_frequency!: UpdateFrequency; //enum
  critical_values!: any;
  possible_values!: any;
  created_at!: string;
  deleted_at!: string;
  updated_at!: string;

  constructor(data: any) {
    Object.assign(this, data)
  }
}

export class FeatureCategory {
  id!: string;
  name!: string;
  description!: string;
  created_at!: string;
  deleted_at!: string;
  updated_at!: string;

  constructor(data: any) {
    Object.assign(this, data)
  }
}


export class FeatureForm {
  name!: string;
  description!: string;
  category_id!: string;
  type: FeatureType = FeatureType.String; //todo remove it from here
  has_integration!: boolean;
  update_frequency!: string; //enum
  day_part!: string; //enum
  critical_values!: any;
  possible_values!: any;

  constructor(data: any = {}) {
    Object.assign(this, data)
  }

  static fromFeature(feature: Feature) {
    return new FeatureForm(feature);
  }
}

export class FeatureCategoryForm {
  name!: string;
  description!: string;
  background!: string;

  constructor(data: any = {}) {
    Object.assign(this, data)
  }

  static fromCategory(category: FeatureCategory) {
    return new FeatureCategoryForm(category);
  }
}
