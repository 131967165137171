import {Activity} from "../../activity/models/Activity";

export class Journey {
  id!: string;
  name!: string;
  description!: string;
  is_active!: boolean;
  is_public!: boolean;
  with_certificate?: boolean
  sessions: JourneySession[] = [];

  constructor(props: any) {
    Object.assign(this, props);
  }
}

export class JourneyFilter {

}

export class SessionActivity {
  id!: number;
  activity!: Activity;
  is_background?: boolean;
  is_continue_after_main?: boolean
}

export class JourneySession {
  id!: string;
  journey_id!: string;
  name!: string;
  description!: string;
  video!: string;
  schedule_time!: string;
  can_same_day!: boolean;
  duration_minutes?: number;
  activities: SessionActivity[] = [];

  constructor(props: any) {
    Object.assign(this, props);
  }
}

export class JourneyActivityForm {
  id!: number;
}

export class JourneySessionForm {
  name: string = '';
  description: string = '';
  video: string = '';
  can_same_day: boolean = false;
  duration_minutes: number = 0;
  activities: JourneyActivityForm[] = [];

  constructor(props: any = {}) {
    Object.assign(this, props);
  }
}

export class JourneyForm {
  name!: string;
  description!: string;
  image?: File;
  mail_list_id?: string;
  is_active: boolean = true;
  is_public: boolean = true;
  with_certificate?: boolean = false;
  sessions: JourneySessionForm[] = [];
}

export interface SessionActivityForm {
  is_background?: boolean;
  is_continue_after_main?: boolean
}
