import SendNotificationFormComponent from "../components/SendNotificationFormComponent";
import userStoreService from "../../common/services/user-store.service";

const routes = [
  {
    path: '/notifications',
    component: SendNotificationFormComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
];

export default routes;
