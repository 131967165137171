import "./BenefitListComponent.scss";
import {useEffect, useState} from "react";
import activityService from "../../services/activity.service";
import {Link} from "react-router-dom";
import {ActivityBenefit, BenefitFilter} from "../../models/Benefit";

const BenefitListComponent = () => {
  const [benefits, setBenefits] = useState<ActivityBenefit[]>([]);

  useEffect(() => {
    const filter = new BenefitFilter(false);
    activityService.getBenefits(filter).then(setBenefits);
  }, []);

  return (
    <div>
      <Link className="button" to={"/benefit"}>Create new Benefit</Link>

      <div className="table full-width">
        <div className="row table-header">
          <div className="cell">Name</div>
          <div className="cell">Type</div>
          <div className="cell">Is active</div>
          <div className="cell">Action</div>
        </div>

        {benefits.map(a => (
          <div className="row" key={a.id}>
            <div className="cell">{a.name}</div>
            <div className="cell">{a.icon}</div>
            <div className="cell">{a.is_active?1:0}</div>
            <div className="cell action">
              <a href={`/benefit/${a.id}`}>Edit</a>
            </div>
          </div>
        ))}

        <div className="row table-footer">
        </div>

      </div>
    </div>
  );
};

export default BenefitListComponent;
