import activityService from "modules/activity/services/activity.service";
import {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import {JourneySession, JourneySessionForm, SessionActivityForm} from "../../models/Journey";
import journeyService from "../../services/journey.service";
import {Activity} from "../../../activity/models/Activity";

function SessionComponent() {
  const [session, setSession] = useState<JourneySession | null>();
  const [activities, setActivities] = useState<Activity[]>([]);
  const [updateForm, setUpdateForm] = useState<JourneySessionForm>(new JourneySessionForm());

  const [form, setForm] = useState({} as SessionActivityForm);
  const { id, sessionId } = useParams<any>();
  const [addActivityId, setAddActivityId] = useState<string>('');
  const [error, setError] = useState<string | null>();

  console.log('Form', updateForm);

  const changeSessionValue = (callback: (form: JourneySessionForm) => void) => {
    const f = {...updateForm};
    callback(f);
    setUpdateForm(f);
  };

  const updateSession = () => {
    if (session) {
      journeyService.updateJourneySession(session.journey_id, sessionId, updateForm);
    }
  };

  const changeValue = (callback: (form: SessionActivityForm) => void) => {
    const f = {...form};
    callback(f);
    setForm(f);
  };

  const addActivity = () => {
    if (addActivityId) {
      journeyService.addSessionActivity(id, sessionId, addActivityId, form).then((s) => {
        setSession(s);
      });
    } else {
      setError('Choose activity to add!');
    }
  };
  const removeActivity = (activityId: string) => {
    journeyService.removeSessionActivity(id, sessionId, activityId).then((s) => {
      setSession(s);
    });
  };
  const changeActivity = (activityId: string) => {
    setAddActivityId(activityId);
    setError(null);
  };

  useEffect(() => {
    journeyService.getJourneySession(id, sessionId).then(s =>{
      setSession(s);
      setUpdateForm(new JourneySessionForm(s));
    });
  }, [id, sessionId]);
  useEffect(() => {
    activityService.getActivities({}, null).then(setActivities);
  }, []);

  return (
    <div>
      SessionComponent
      <div>
        <div className="form-field">
          <label>Name</label>
          <input type="text" onChange={e => changeSessionValue(f => f.name = e.target.value)} name="name" value={updateForm.name} />
        </div>
        <div className="form-field">
          <label>Duration(min)</label>
          <input type="number" onChange={e => changeSessionValue(f => f.duration_minutes = +e.target.value)} name="duration_minutes" value={updateForm.duration_minutes} />
        </div>
        <div className="form-field">
          <label>Is background activity</label>
          <input type="checkbox" onChange={e => changeSessionValue(f => f.can_same_day = e.target.checked)} name="can_same_day" checked={updateForm.can_same_day} />
        </div>
        <button onClick={() => updateSession()}>Update Session</button>
      </div>
      <p>{session?.description}</p>

      <h3>Activities</h3>
      <div className="table">
        <div className="row header">
          <div className="cell">Name</div>
          <div className="cell">Description</div>
          <div className="cell">Background</div>
          <div className="cell">Continue after main</div>
          <div className="cell">Action</div>
        </div>

        {(session?.activities || []).map(a => (
          <div className="row header" key={a.id}>
            <div className="cell">{a.activity.name}</div>
            <div className="cell">{a.activity.description}</div>
            <div className="cell">{a.is_background ? 1 : 0}</div>
            <div className="cell">{a.is_continue_after_main ? 1 : 0}</div>
            <div className="cell">
              <button onClick={() => removeActivity(a.activity.id)}>Remove</button>
            </div>
          </div>
        ))}
      </div>


      <div>
        <select value={addActivityId} onChange={e => changeActivity(e.target.value)}>
          <option>Select activity</option>
          {activities.map(a => (
            <option value={a.id} key={a.id}>{a.name}</option>
          ))}
        </select>
        <span style={{color: "red"}}>{error}</span>

        <div className="form-field">
          <label>Is background activity</label>
          <input type="checkbox" onChange={e => changeValue(f => f.is_background = e.target.checked)} name="is_background" checked={form.is_background} />
        </div>

        <div className="form-field">
          <label>Should continue after main ends</label>
          <input type="checkbox" onChange={e => changeValue(f => f.is_continue_after_main = e.target.checked)} name="is_continue_after_main" checked={form.is_continue_after_main} />
        </div>

        <button onClick={() => addActivity()}>Add activity</button>
      </div>
    </div>
  );
}

export default SessionComponent;

