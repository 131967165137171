import {useCallback, useEffect, useState} from "react";
import journeyService from "../../services/journey.service";
import {Journey, JourneyFilter} from "../../models/Journey";
import "./JourneyListComponent.scss";
import {Link} from "react-router-dom";


export default function JourneyListComponent() {
  const [programs, setPrograms] = useState<Journey[]>([]);

  const loadJourneys = useCallback(() => {
    const filter = new JourneyFilter();
    journeyService.getJourneys(filter).then(setPrograms);
  }, []);

  useEffect(() => {
    loadJourneys();
  }, [loadJourneys]);

  const deleteProgram = (program: Journey) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Are you sure you want to delete Program?")) {
      journeyService.deleteJourney(program.id).then(() => {
        loadJourneys();
      });
    }
  };

  return (
    <div>
      <div>
        <Link to={`/journey`}>Create</Link>
      </div>

      <div className="table">

        <div className="row header-row">
          <div className="cell header-cell">name</div>
          <div className="cell header-cell">description</div>
          <div className="cell header-cell">Action</div>
        </div>

        {programs.map(program =>
          <div className="row" key={program.id}>
            <div className="cell">{program.name}</div>
            <div className="cell">{program.description}</div>
            <div className="cell">
              <Link to={`/journey/${program.id}`}>Edit</Link>
              <Link to={`/journey/${program.id}`}>Activate</Link>
              <button onClick={() => deleteProgram(program)}>Delete</button>
            </div>
          </div>
        )}

      </div>
    </div>
  );
}
