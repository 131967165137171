import {useHistory, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {FeatureCategory, FeatureDayPart, FeatureForm, UpdateFrequency} from "../../models/feature";
import featureService from "../../services/feature.service";

const FeatureComponent = () => {
  const {id} = useParams<{id?: string}>();
  const [form, setForm] = useState<FeatureForm>(new FeatureForm());
  const [categories, setCategories] = useState<FeatureCategory[]>([]);
  const history = useHistory();

  const changeValue = (callback: (form: FeatureForm) => void) => {
    const f = {...form};
    callback(f)
    setForm(f);
  };
  const saveFeature = () => {
    console.log('update feature', form);
    (id ? featureService.updateFeature(id, form) : featureService.createFeature(form)).then(c => {
      history.push("/features");
    });
  };

  useEffect(() => {
    if (id) {
      featureService.getFeature(id).then(category => {
        setForm(FeatureForm.fromFeature(category))
      });
    }
  }, [id]);

  useEffect(() => {
    featureService.getFeatureCategories().then(setCategories);
  }, []);

  return (
    <div>
      FEATURE FORM

      <div className="form-field">
        <label>Category</label>
        <select className="form-input select" onChange={(e) => changeValue(f => f.category_id = e.target.value)} value={form.category_id}>
          <option>Choose category</option>
          {categories.map(a => (
            <option key={a.id} value={a.id}>{a.name}</option>
          ))}
        </select>
      </div>

      <div className="form-field">
        <label>Update Frequency</label>
        <select className="form-input select" onChange={(e) => changeValue(f => f.update_frequency = e.target.value)} value={form.update_frequency}>
          <option>Choose frequency</option>
          {Object.entries(UpdateFrequency).map(a => (
            <option key={a[1]} value={a[1]}>{a[0]}</option>
          ))}
        </select>
      </div>

      <div className="form-field">
        <label>Part of the day</label>
        <select className="form-input select" onChange={(e) => changeValue(f => f.day_part = e.target.value)} value={form.day_part}>
          <option>Choose part</option>
          {Object.entries(FeatureDayPart).map(a => (
            <option key={a[1]} value={a[1]}>{a[0]}</option>
          ))}
        </select>
      </div>

      <div className="form-field">
        <label>Name</label>
        <input type="text" onChange={e => {changeValue(f => f.name = e.target.value)}} name="name" value={form?.name} />
      </div>

      <div className="form-field">
        <label>Description</label>
        <textarea onChange={e => {changeValue(f => f.description = e.target.value)}} name="description" value={form?.description} />
      </div>

      <div className="form-field">
        <label>Has Integration</label>
        <input type="checkbox" onChange={e => changeValue(f => f.has_integration = !f.has_integration)} checked={form?.has_integration} name="has_integration" />
      </div>

      <button onClick={saveFeature}>Save</button>

    </div>
  );
};

export default FeatureComponent;
