import userStoreService from "../../common/services/user-store.service";
import ReferralFormComponent from "../components/ReferralFormComponent/ReferralFormComponent";
import ReferralListComponent from "../components/ReferralListComponent/ReferralListComponent";

const routes = [
  {
    path: '/referral/:id',
    component: ReferralFormComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
  {
    path: '/referral',
    component: ReferralFormComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
  {
    path: '/referrals',
    component: ReferralListComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
];

export default routes;
