import fetchService from "../../common/services/fetch.service";
import {ReferralCodeFilter} from "../models/ReferralCodeFilter";
import Pagination from "../../common/models/Pagination";
import {ReferralCodeForm} from "../models/ReferralCodeForm";
import {ReferralCode} from "../models/ReferralCode";

class ReferralCodeService {
  getReferrals(filter?: ReferralCodeFilter, pagination?: Pagination) {
    const search = fetchService.objectToPath(filter);
    const paging = fetchService.objectToPath(pagination);
    return fetchService.fetchApi<ReferralCode[]>(`/api/admin/referrals?${search}&${paging}`, {
      method: "GET"
    });
  }

  getReferral(id: string) {
    return fetchService.fetchApi<ReferralCode[]>(`/api/admin/referrals`, {
      method: "GET"
    }).then(codes => {
      return codes.find(code => code.id === id);
    });
  }

  createReferral(form: ReferralCodeForm) {
    return fetchService.fetchApi(`/api/admin/referral`, {
      method: "POST",
      body: JSON.stringify(form)
    });
  }

  updateReferral(id: string, form: ReferralCodeForm) {
    return fetchService.fetchApi(`/api/admin/referral/${id}`, {
      method: "PATCH",
      body: JSON.stringify(form)
    });
  }
}

const referralCodeService = new ReferralCodeService();
export default referralCodeService;
