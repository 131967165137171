import Pagination from "../../common/models/Pagination";
import fetchService from "../../common/services/fetch.service";
import {Activity} from "../models/Activity";
import {ActivityForm} from "../models/ActivityForm";
import {ActivityBenefit, BenefitFilter, BenefitForm} from "../models/Benefit";
import {ActivityFactory} from "../models/ActivityFactory";

class ActivityService {

  getActivities(filter: any, pagination: Pagination | null = null): Promise<Activity[]> {
    const search = fetchService.objectToPath(filter);
    const paging = fetchService.objectToPath(pagination);
    return fetchService.fetchApi<Activity[]>(`/api/admin/activities?${search}&${paging}`, {
      method: "GET"
    }).then(activities => {
      return activities.map(ActivityFactory.initObject)
    });
  }

  getActivity(id: string): Promise<Activity> {
    return fetchService.fetchApi(`/api/admin/activity/${id}`, {
      method: "GET"
    });
  }

  createActivity(data: ActivityForm): Promise<Activity> {
    return fetchService.fetchApi(`/api/admin/activity`, {
      method: "POST",
      body: JSON.stringify(data)
    });
  }

  updateActivity(id: string, data: ActivityForm): Promise<Activity> {
    return fetchService.fetchApi(`/api/admin/activity/${id}`, {
      method: "PATCH",
      body: JSON.stringify(data)
    });
  }

  deleteActivity(id: string) {
    return fetchService.fetchApi(`/api/admin/activity/${id}`, {
      method: "DELETE",
    });
  }

  getBenefits(filter: BenefitFilter) {
    const search = fetchService.objectToPath(filter);
    return fetchService
      .fetchApi<ActivityBenefit[]>(`/api/admin/benefits?${search}`, {
        method: 'GET',
      })
      .then(benefits => benefits.map(a => new ActivityBenefit(a)));
  }

  getBenefit(id: number) {
    return fetchService
      .fetchApi<ActivityBenefit>(`/api/admin/benefit/${id}`, {
        method: 'GET',
      })
      .then(benefit => new ActivityBenefit(benefit));
  }

  createBenefit(data: BenefitForm): Promise<Activity> {
    return fetchService.fetchApi(`/api/admin/benefit`, {
      method: "POST",
      body: JSON.stringify(data)
    });
  }

  updateBenefit(id: number, data: BenefitForm): Promise<Activity> {
    return fetchService.fetchApi(`/api/admin/benefit/${id}`, {
      method: "PATCH",
      body: JSON.stringify(data)
    });
  }

}

const activityService = new ActivityService();
export default activityService;
