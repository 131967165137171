import userStoreService from "../../common/services/user-store.service";
import DemoComponent from "../components/DemoComponent/DemoComponent";

const routes = [
  {
    path: '/demo',
    component: DemoComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null
  },
];

export default routes;
