import {Activity} from "../../activity/models/Activity";

export class JourneySession {
  id!: string;
  journey_id!: string;
  name?: string;
  description?: string;
  image?: string;
  image_path!: string;
  video_path!: string;

  constructor(props: any) {
    Object.assign(this, props);
  }
}

export class UserJourneySession {
  id!: string;

  scheduled_at!: Date;
  started_at!: Date | null;
  completed_at!: Date | null;
  activities: UserSessionActivity[] = [];
  session: JourneySession;

  constructor(props: any) {
    ['scheduled_at', 'started_at', 'completed_at'].forEach(field => {
      if (props[field] !== null) {
        props[field] = new Date(props[field]);
      }
    });
    Object.assign(this, props);
    this.session = new JourneySession(props.session);
    // this.activities = this.activities.map(activity => new PlanActivity(activity));
  }
}

export class UserSessionActivity {
  id!: string;
  scheduled_at!: Date | null;
  started_at!: Date | null;
  completed_at!: Date | null;
  activity!: Activity;
  session!: UserJourneySession;
  rate!: number;

  constructor(props: any) {
    console.log('Constructor, UserActivity', props);
    ['scheduled_at', 'started_at', 'completed_at'].forEach(field => {
      if (props[field] !== null) {
        props[field] = new Date(props[field]);
      }
    });
    Object.assign(this, props);
    this.activity = new Activity(this.activity);
  }
}
