import {useHistory, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {FeatureCategoryForm} from "../../models/feature";
import featureService from "../../services/feature.service";

const FeatureCategoryComponent = () => {
  const {id} = useParams<{id?: string}>();
  const [form, setForm] = useState<FeatureCategoryForm>(new FeatureCategoryForm());
  const history = useHistory();

  const changeValue = (field: string, value: any) => {
    const f = {...form};
    f[field as keyof FeatureCategoryForm] = value;
    setForm(f);
  };
  const saveCategory = () => {
    (id ? featureService.updateCategory(id, form) : featureService.createCategory(form)).then(c => {
      history.push("/feature/categories");
    });
  };

  useEffect(() => {
    console.log('load', id);
    if (id) {
      featureService.getFeatureCategory(id).then(category => {
        setForm(FeatureCategoryForm.fromCategory(category))
      });
    }
  }, [id]);

  return (
    <div className="form">
      Feature CAtegory form
      <div className="form-field">
        <label>Name</label>
        <input type="text" onChange={e => {changeValue('name', e.target.value)}} name="name" value={form?.name} />
      </div>

      <div className="form-field">
        <label>Description</label>
        <textarea onChange={e => {changeValue('description', e.target.value)}} name="description" value={form?.description} />
      </div>

      <div className="form-field">
        <label>Background</label>
        <textarea onChange={e => {changeValue('background', e.target.value)}} name="background" value={form?.background} />
      </div>

      <button onClick={saveCategory}>Save</button>

    </div>
  );
};

export default FeatureCategoryComponent;
