export class ActivityBenefit {
  id!: number;
  name!: string;
  icon!: string;
  icon_path!: string;
  image!: string;
  image_path!: string;
  is_active!: boolean;

  constructor(props: any) {
    return Object.assign(this, props);
  }
}

export class BenefitFilter {
  constructor(
    public with_activities: boolean,
    public categories?: string[],
    public is_active?: boolean,
  ) {
  }
}

export class BenefitForm {
  constructor(public name?: string, public icon?: string, public image?: string, public is_active?: boolean) {
  }

  static fromBenefit(data: any) {
    const form = new BenefitForm();
    Object.assign(form, data);
    return form;
  }
}
